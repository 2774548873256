import Vue from 'vue'
import { cloneDeep } from 'lodash'

//State for safetyInspections details view (step 1, 2, 3, 4)
export default {
  name: 'safetyInspections',
  namespaced: true,
  state: {
    safetyInspection: {},
    hasChanges: false,
    isValid: false,
    validateForm: false,
    templatePickerExpanded: false
  },
  mutations: {
    SET_SAFETY_INSPECTION(state, safetyInspection) {
      Vue.set(state, 'safetyInspection', safetyInspection)
    },
    SET_CREATED_BY(state, createdBy) {
      Vue.set(state.safetyInspection, 'createdBy', createdBy)
    },
    SET_TITLE(state, title) {
      Vue.set(state.safetyInspection, 'title', title)
    },
    SET_COMPLETION_DATE(state, completionDate) {
      Vue.set(state.safetyInspection, 'completionDate', completionDate)
    },
    SET_DEPARTMENT_ID(state, departmentId) {
      Vue.set(state.safetyInspection, 'departmentId', departmentId)
    },
    SET_LOCATION(state, location) {
      Vue.set(state.safetyInspection, 'location', location)
    },
    SET_DESCRIPTION(state, description) {
      Vue.set(state.safetyInspection, 'description', description)
    },
    SET_CATEGORIES(state, categories) {
      Vue.set(state.safetyInspection, 'categories', categories)
    },
    SET_CONTACT_PARTICIPANTS(state, contactParticipants) {
      state.safetyInspection.contactParticipants = contactParticipants
    },
    SET_EMPLOYEE_PARTICIPANTS(state, employeeParticipants) {
      state.safetyInspection.employeeParticipants = employeeParticipants
    },
    SET_HAS_CHANGES(state, hasChanges) {
      state.hasChanges = hasChanges
    },
    SET_IS_VALID(state, isValid) {
      state.isValid = isValid
    },
    SET_VALIDATE_FORM(state, validateForm) {
      state.validateForm = validateForm
    },
    SET_TEMPLATE_PICKER_EXPANDED(state, templatePickerExpanded) {
      state.templatePickerExpanded = templatePickerExpanded
    }
  },
  actions: {
    setSafetyInspection({ commit, state }, payload) {
      commit('SET_SAFETY_INSPECTION', payload)
    },
    setExistingSafetyInspection({ commit, state }, payload) {
      commit('SET_SAFETY_INSPECTION', payload)
      commit('SET_HAS_CHANGES', false)
      commit('SET_IS_VALID', true)
      commit('SET_VALIDATE_FORM', false)
    },
    useSafetyInspectionTemplate({ commit, state }, payload) {
      const { title, description, categories } = payload
      const cleanedCategories = categories.map(category => {
        const { id, title, sortIndex, checkpoints } = category
        return {
          title,
          sortIndex,
          checkpoints: checkpoints.map(c => {
            const { title, sortIndex } = c
            return { title, sortIndex }
          })
        }
      })

      commit('SET_SAFETY_INSPECTION', {
        title,
        description,
        categories: cleanedCategories
      })
      commit('SET_HAS_CHANGES', false)
      commit('SET_IS_VALID', false)
      commit('SET_VALIDATE_FORM', false)
    },
    updateSafetyInspectionCategories({ commit, state }, payload) {
      commit('SET_CATEGORIES', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateCreatedBy({ commit, state }, payload) {
      commit('SET_CREATED_BY', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateTitle({ commit, state }, payload) {
      commit('SET_TITLE', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateCompletionDate({ commit, state }, payload) {
      commit('SET_COMPLETION_DATE', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateDepartmentId({ commit, state }, payload) {
      commit('SET_DEPARTMENT_ID', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateLocation({ commit, state }, payload) {
      commit('SET_LOCATION', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateDescription({ commit, state }, payload) {
      commit('SET_DESCRIPTION', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateContactParticipants({ commit, state }, payload) {
      commit('SET_CONTACT_PARTICIPANTS', payload)
      commit('SET_HAS_CHANGES', true)
    },
    updateEmployeeParticipants({ commit, state }, payload) {
      commit('SET_EMPLOYEE_PARTICIPANTS', payload)
      commit('SET_HAS_CHANGES', true)
    },

    setFromValidation({ commit, state }, payload) {
      commit('SET_IS_VALID', payload)
    },
    setHasChanges({ commit, state }, payload) {
      commit('SET_HAS_CHANGES', payload)
    },
    clear({ commit, state }) {
      commit('SET_SAFETY_INSPECTION', {})
      commit('SET_HAS_CHANGES', false)
      commit('SET_VALIDATE_FORM', false)
    },
    startFormValidation({ commit, state }) {
      commit('SET_VALIDATE_FORM', true)
    },
    completeFormValidation({ commit, state }) {
      commit('SET_VALIDATE_FORM', false)
    },
    updateCategoryCheckpoint({ commit, state }, payload) {
      const updatedCategories = cloneDeep(
        state.safetyInspection.categories
      ).map(category => {
        if (category.id === payload.categoryId) {
          const updatedCheckpoints = (category.checkpoints || []).map(
            checkpoint => {
              return checkpoint.id === payload.id
                ? { ...checkpoint, ...payload }
                : checkpoint
            }
          )

          return { ...category, checkpoints: updatedCheckpoints }
        }
        return category
      })

      commit('SET_CATEGORIES', updatedCategories)
      commit('SET_HAS_CHANGES', true)
    },
    toggleTemplatePicker({ commit, state }, payload) {
      commit('SET_TEMPLATE_PICKER_EXPANDED', payload)
    }
  },
  getters: {
    safetyInspection: state => {
      return state.safetyInspection ?? null
    },
    isValidForm: state => {
      return state.isValid
    },
    hasChanges: state => {
      return state.hasChanges
    },
    validateForm: state => {
      return state.validateForm
    },
    categories: state => {
      return state.safetyInspection?.categories ?? []
    },
    isTemplatePickerExpanded: state => {
      return state.templatePickerExpanded
    }
  }
}
