<template>
  <div class="align-center">
    <svg
      :width="size"
      viewBox="0 0 143 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      :aria-label="$t('admin_services__navbar_avonova_logo_accessible_label')"
    >
      <path
        d="M134.676 15.3438C133.985 15.6305 133.266 15.758 132.564 15.7431C132.582 15.7431 132.602 15.7431 132.62 15.7415C133.24 15.7249 133.866 15.5957 134.474 15.3438C137.146 14.237 138.415 11.1735 137.309 8.50265C136.493 6.53595 134.618 5.3281 132.617 5.27176C132.594 5.27011 132.569 5.27011 132.544 5.27011C134.646 5.21874 136.659 6.44482 137.511 8.50265C138.617 11.1735 137.348 14.237 134.676 15.3438Z"
        :fill="color"
      />
      <path
        d="M37.0358 1.01196L32.079 14.6098L27.1618 1.01196H21.5969L29.1002 19.9996H34.9981L42.4251 1.01196H37.0358Z"
        :fill="color"
      />
      <path
        d="M74.1197 0.5C71.9643 0.5 69.8851 1.22571 68.5349 3.24376V1.01031H63.5581V19.9996H68.6691V9.59121C68.6691 6.73146 70.4915 5.30325 72.6866 5.30325C75.0159 5.30325 76.3894 6.81099 76.3894 9.55476V19.998H81.5036V7.90783C81.5053 3.49892 78.4304 0.5 74.1197 0.5Z"
        :fill="color"
      />
      <path
        d="M118.129 1.01196L113.172 14.6098L108.255 1.01196H102.69L110.193 19.9996H116.091L123.518 1.01196H118.129Z"
        :fill="color"
      />
      <path
        d="M51.6646 0.508301C46.1445 0.508301 41.6697 4.98349 41.6697 10.5042C41.6697 16.0248 46.1445 20.5 51.6646 20.5C57.1848 20.5 61.6579 16.0248 61.6579 10.5042C61.6579 4.98349 57.1848 0.508301 51.6646 0.508301ZM51.6646 15.7415C48.7737 15.7415 46.4278 13.3954 46.4278 10.5042C46.4278 7.61293 48.7737 5.26681 51.6646 5.26681C54.5556 5.26681 56.8999 7.61293 56.8999 10.5042C56.8999 13.3954 54.5556 15.7415 51.6646 15.7415Z"
        :fill="color"
      />
      <path
        d="M93.4304 0.508301C87.9103 0.508301 83.4371 4.98349 83.4371 10.5042C83.4371 16.0248 87.9103 20.5 93.4304 20.5C98.9506 20.5 103.425 16.0248 103.425 10.5042C103.425 4.98349 98.949 0.508301 93.4304 0.508301ZM93.4304 15.7415C90.5395 15.7415 88.1952 13.3954 88.1952 10.5042C88.1952 7.61293 90.5395 5.26681 93.4304 5.26681C96.3231 5.26681 98.6673 7.61293 98.6673 10.5042C98.6673 13.3954 96.3231 15.7415 93.4304 15.7415Z"
        :fill="color"
      />
      <path
        d="M14.874 1.00866V3.10625C13.6182 1.55874 11.7958 0.5 9.19145 0.5C3.95789 0.5 0 4.79293 0 10.4959C0 16.1971 3.95789 20.4901 9.18814 20.4884C11.7958 20.4884 13.6364 19.4297 14.8707 17.9004V19.998H19.869V1.00866H14.874ZM11.6401 15.3438C11.0321 15.5957 10.4058 15.7249 9.78621 15.7415C7.67722 15.8028 5.65437 14.5767 4.7995 12.5106C3.69282 9.83808 4.9602 6.77454 7.63249 5.66776C8.33659 5.37615 9.06886 5.25023 9.7829 5.27177C11.7842 5.3281 13.6596 6.53595 14.4747 8.50265C15.5814 11.1735 14.3124 14.2371 11.6401 15.3438Z"
        :fill="color"
      />
      <path
        d="M137.708 1.00866V3.10625C136.452 1.55874 134.63 0.5 132.025 0.5C126.792 0.5 122.834 4.79293 122.834 10.4959C122.834 16.1971 126.792 20.4901 132.022 20.4884C134.63 20.4884 136.47 19.4297 137.704 17.9004V19.998H142.703V1.00866H137.708ZM132.535 15.7431C130.456 15.7697 128.477 14.5485 127.633 12.5106C126.527 9.83808 127.794 6.77454 130.466 5.66776C131.141 5.38775 131.84 5.26183 132.526 5.27011H132.544C132.569 5.27011 132.593 5.27011 132.617 5.27177C134.618 5.3281 136.493 6.53595 137.308 8.50265C138.415 11.1735 137.146 14.2371 134.474 15.3438C133.866 15.5957 133.24 15.7249 132.62 15.7415C132.602 15.7431 132.582 15.7431 132.564 15.7431H132.535Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AvonovaTextLogo',
  props: {
    size: { type: Number },
    color: { type: String, default:"#00211B" }
  },
  components: {},
  setup() {}
}
</script>
