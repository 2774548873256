/// Because of hard dependency of useFileApi in frontend_storybook we need to do this
import { useFilesService } from '@/services'
import { escapeUnicode } from '@/utilities/fileUtilities'
const {
  getAllFilesForUser,
  downloadFile,
  getFileCategories,
  getFileInfo,
  deleteFile,
  previewFile,
  updateFile,
  getFileListForType,
  getFileCountsByOwnerIds
} = useFilesService()

export const useFileApi = () => {
  async function updateFileInfo(file = null) {
    return await updateFile(file.id, file)
  }
  return {
    loadFileInfo: getFileInfo,
    updateFileInfo,
    deleteFile: deleteFile,
    previewFile: previewFile,
    downloadFile: downloadFile,
    getAllFilesForUser: getAllFilesForUser,
    getFileListForType: getFileCountsByOwnerIds,
    loadFileCategories: getFileCategories
  }
}
