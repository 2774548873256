export const isLegacyUrl = url => url.includes('.xhtml')

export const toModernUrl = url => url.replace(/.xhtml/i, '')

export const toLegacyUrl = url => {
  if (isLegacyUrl(url)) return url;

  const [baseUrl, queryParams] = url.split('?');
  return queryParams ? `${baseUrl}.xhtml?${queryParams}` : `${baseUrl}.xhtml`;
};
