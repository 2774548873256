const settingsRoutes = [
  {
    name: 'Prosjektadministrasjon',
    path: '/settings/projects/:id?',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ProjectsHero" */ `@/views/settings/project/ProjectsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Projects" */ `@/views/settings/project/Projects.vue`
        )
    },
    meta: {
      title: 'meta__title__projects',
      contentful: {
        category: 'PTRYWviqqOePp2mXVa86B',
        pageHelp: 'R7oV0MFgjt6o7oew65ua9'
      },
      breadcrumbs: [
        {
          text: 'Innstillinger',
          string: 'menu__item__title__settings',
          disabled: false,
          href: '/startside/dashboards/dashboard_settings.xhtml'
        },
        {
          text: 'Prosjektadministrasjon',
          string: 'projects__title__list',
          disabled: true,
          href: '/settings/projects'
        }
      ]
    },
    productionAvailable: true
  }
]

export default settingsRoutes
