import i18n from '@/config/i18n'
const originList = {
  NONE: {
    contentfulKey: 'None'
  },

  ANSATTE: {
    contentfulKey: 'files_page__owner_type__employees',
    route: id => `/ansatte/editAnsatt.xhtml?id=${id}`
  },

  ANSATT_ADVARSEL: {
    contentfulKey: 'files_page__owner_type__employee_warning',
    route: id => `/ansatte/skjemaer/advarsel.xhtml?id=${id}`
  },

  ANSATT_DROFTELSESMOTE: {
    contentfulKey: 'files_page__owner_type__employee_discussion_meeting',
    route: id => `/ansatte/skjemaer/droftelsesmote.xhtml?id=${id}`
  },

  ANSATT_MEDARBEIDERSAMTALE: {
    contentfulKey: 'files_page__owner_type__employee_interview',
    route: id => `/ansatte/skjemaer/medarbeidersamtale.xhtml?id=${id}`
  },

  ANSATT_OPPSIGELSE_ARBEIDSTAKERSFORHOLD: {
    contentfulKey:
      'files_page__owner_type__employee_resignation_working_conditions',
    route: id =>
      `/ansatte/skjemaer/oppsigelsearbeidstakerforhold.xhtml?id=${id}`
  },

  ANSATT_OPPSIGELSE_AVSKJED: {
    contentfulKey: 'files_page__owner_type__employee_resignation_notice',
    route: id => `/ansatte/skjemaer/avskjed.xhtml?id=${id}`
  },

  ANSATT_OPPSIGELSE_PROVETID: {
    contentfulKey: 'files_page__owner_type__employee_resignation_probation',
    route: id => `/ansatte/skjemaer/oppsigelseprovetid.xhtml?id=${id}`
  },

  ANSATT_OPPSIGELSE_VIRKSOMHETENSFORHOLD: {
    contentfulKey:
      'files_page__owner_type__employee_resignation_business_conditions',
    route: id => `/ansatte/skjemaer/oppsigelsevirksomhetforhold.xhtml?id=${id}`
  },

  ANSATT_PERMITTERING: {
    contentfulKey: 'files_page__owner_type__employee_grant_leave',
    route: id => `/ansatte/skjemaer/varselompermitering.xhtml?id=${id}`
  },

  ANSATT_SLUTTATTEST: {
    contentfulKey: 'files_page__owner_type__employee_work_record',
    route: id => `/ansatte/skjemaer/sluttattest.xhtml?id=${id}`
  },

  ANSATT_TAUSHETSERKLAERING: {
    contentfulKey: 'files_page__owner_type__employee_non_disclosure_agreement',
    route: id => `/ansatte/skjemaer/taushetserklaering.xhtml?id=${id}`
  },

  ANSATT_VERNEUTSTYR: {
    contentfulKey: 'files_page__owner_type__employee_safety_equipments',
    route: id => `/ansatte/skjemaer/utlevertverneutstyr.xhtml?id=${id}`
  },

  ARBEIDSAVTALE: {
    contentfulKey: 'files_page__owner_type__employment_contract',
    route: id => `/ansatte/skjemaer/arbeidsavtale.xhtml?id=${id}`
  },

  ARBEIDSUTSTYR: {
    contentfulKey: 'files_page__owner_type__work_equipments',
    route: id => `/arbeidsutstyr/editArbeidsutstyr.xhtml?id=${id}`
  },

  AVTALER: {
    contentfulKey: 'files_page__owner_type__agreements',
    route: id => `/card-file/agreements/edit/${id}`
  },

  CKEDITOR: {
    contentfulKey: 'files_page__owner_type__ck_editor'
  },

  CKEDITOR_PUBLIC: {
    contentfulKey: 'files_page__owner_type__ck_editor_public'
  },

  EDITVERNERUNDE: {
    contentfulKey: 'files_page__owner_type__edit_safety_inspection',
    route: id => `/hendelsesregister/vernerunde/editVernerunde.xhtml?id=${id}`
  },

  FORSLAG: {
    contentfulKey: 'files_page__owner_type__suggestion',
    route: id => `/hendelsesregister/forslag/editForslag.xhtml?id=${id}`
  },

  HENDELSE: {
    contentfulKey: 'files_page__owner_type__incident',
    route: id => `/incidentregistry/incidents/${id}`
  },

  INDEPENDENT_COMPETENCE: {
    contentfulKey: 'files_page__owner_type__independent_competency',
    route: id => `/kompetanseoversikt/competence_registration.xhtml?id=${id}`
  },

  JSA: {
    contentfulKey: 'files_page__owner_type__safe_job_analysis',
    route: id => `/risikosenter/jsa/jobsafetyanalysis_edit.xhtml?id=${id}`
  },

  JSA_RISK: {
    contentfulKey: 'files_page__owner_type__safe_job_analysis_risk',
    route: id => `/risikosenter/jsa/jobsafetyanalysis_edit.xhtml?id=${id}`
  },

  KOMPETANSE: {
    contentfulKey: 'files_page__owner_type__competency',
    route: () => `/kompetanseoversikt/kompetanseoversikt.xhtml`
  },

  LOGO: {
    contentfulKey: 'files_page__owner_type__logo'
  },

  LOKALE: {
    contentfulKey: 'files_page__owner_type__premises',
    route: id => `/company/premises/${id}`
  },

  MINEDOKUMENTER: {
    contentfulKey: 'files_page__owner_type__my_documents',
    route: () => '/ansatte/minside/minSide.xhtml'
  },

  MOTE: {
    contentfulKey: 'files_page__owner_type__meeting',
    route: id => `/moter/editMote.xhtml?id=${id}`
  },

  MY_COMPETENCE: {
    contentfulKey: 'files_page__owner_type__my_competency',
    route: id => `/employee/competence/edit/${id}`
  },

  OPPFOLGING_SYKEMELDING: {
    contentfulKey: 'files_page__owner_type__sick_leave_follow_up',
    route: id => `/employer/absence/follow-up/${id}/followup-form`
  },

  ORGCHARTUPLOAD: {
    contentfulKey: 'files_page__owner_type__organisational_chart',
    route: () => `/company/organizational-charts`
  },

  PROSJEKT: {
    contentfulKey: 'files_page__owner_type__project',
    route: id => `/settings/projects/${id}`
  },

  REVIDERING: {
    contentfulKey: 'files_page__owner_type__revision',
    route: id => `/hendelsesregister/revisjon/editRevision.xhtml?id=${id}`
  },

  REVISJONSJEKKPUNKT: {
    contentfulKey: 'files_page__owner_type__audit_checkpoint',
    route: id => `/hendelsesregister/revisjon/editRevision.xhtml?id=${id}`
  },

  RISK: {
    contentfulKey: 'files_page__owner_type__risk',
    route: id => `/risikosenter/ros/editRiskAssessment.xhtml?id=${id}`
  },

  RISKASSESSMENT: {
    contentfulKey: 'files_page__owner_type__risk_assesment',
    route: id => `/risikosenter/ros/editRiskAssessment.xhtml?id=${id}`
  },

  SHARED_WITH_EMPLOYEE: {
    contentfulKey: 'files_page__shared_with_employee',
    route: () => '/ansatte/minside/minSide.xhtml'
  },

  STARTSIDEELEMENT: {
    contentfulKey: 'files_page__owner_type__homepage_element'
  },

  STOFFKARTOTEK: {
    contentfulKey: 'files_page__owner_type__dangerous_substances',
    route: id => `/card-file/dangeroussubstances/${id}`
  },

  STOFF: {
    contentfulKey: 'files_page__owner_type__dangerous_substances',
    route: id => `/card-file/dangeroussubstances/${id}`
  },

  STYRENDEDOKUMENT: {
    contentfulKey: 'files_page__owner_type__governing_document',
    route: id => `/styrendedokumenter/editStyrendeDokument.xhtml?id=${id}`
  },

  TILSYNSRAPPORT: {
    contentfulKey: 'files_page__owner_type__supervision_report',
    route: id => `/incidentregistry/inspections/${id}`
  },

  TILTAK: {
    contentfulKey: 'files_page__owner_type__measures',
    route: id => `/incidentRegistry/actionplan/${id}`
  },

  VERNERUNDESJEKKPUNKT: {
    contentfulKey: 'files_page__owner_type__safety_inspection_checkpoint',
    route: id => `/hendelsesregister/vernerunde/editVernerunde.xhtml?id=${id}`
  }
}

export const originTitles = origin => originList[origin].contentfulKey

export const originRoute = (origin, ownerId) =>
  originList[origin].route(ownerId)

export const addOriginTitleAndRoute = item => {
  const reference = item.referedin?.length > 0 ? item.referedin[0] : null
  const file = { ...item }
  if (reference) {
    file.displayableOwnerType = i18n.t(originTitles(reference.type))
    file.routeToOrigin = originRoute(reference.type, reference.id)
  }

  return file
}
