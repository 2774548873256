const getters = {
  initData: state => {
    return state.initData
  },

  loggedInUserId: state => {
    return state?.initData?.employee_id
  },

  accountCountryCode: state => state.initData.accountCountry, // ISO 3166 alpha-2

  hasMenuAccess: state => menuAccessItem => {
    // If the menuAccessItem is null or undefined, then the route is available to all users
    if (menuAccessItem == null) return true
    return state.initData.menuaccess.includes(menuAccessItem)
  },

  isUserWithoutEmployee: (state, getters) => getters.loggedInUserId === null && state?.initData?.user_id
}
export default getters
