<template>
  <div
    :class="[
      'notifications-wrapper',
      isMobile($vuetify) ? 'notifications-mobile' : 'notifications-non-mobile'
    ]"
  >
    <template v-for="notification in activeNotifications">
      <v-alert
        :key="notification.id"
        :color="notification.color"
        :class="['mb-2', notification.color]"
      >
        <span>{{ notification.text }}</span>
      </v-alert>
    </template>
  </div>
</template>

<script>
/* Notifications should have the following data structure: { text: String, color: String, duration(optional): Number } */
import { ref } from 'vue'
import { responsiveFunctions } from '@/composable/common/responsive.js'
export default {
  name: 'Notifications',
  setup() {
    const activeNotifications = ref([])
    const { isMobile } = responsiveFunctions()
    return { activeNotifications, isMobile }
  },
  methods: {
    cleanup(notification) {
      // Remove notification after x amount of time (ms)
      setTimeout(() => {
        const index = this.activeNotifications.findIndex(
          n => n.id === notification.id
        )
        if (index > -1) this.activeNotifications.splice(index, 1)
      }, notification.duration || 3000)
    }
  },
  watch: {
    '$store.state.notifications'() {
      // Pop all notifications from store and push them into "activeNotifications"
      while (this.$store.state.notifications.length > 0) {
        this.$store.dispatch('popNotification').then(notification => {
          notification.id =
            new Date().getTime() + (notification.text || Math.random())
          this.activeNotifications.push(notification)
          this.cleanup(notification)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.notifications-wrapper {
  z-index: 1;
  position: fixed;
  &.notifications-non-mobile {
    top: 110px;
    right: 30px;
  }
  &.notifications-mobile {
    top: 90px;
    width: calc(100% - 16px);
    margin: 0 8px;
  }
  .darkgreen {
    color: white;
  }
  .primary {
    color: white;
  }
  .secondary {
    color: black;
  }
  .accent {
    color: black;
  }
  .error {
    color: white;
  }
}
</style>
