import Vue, { ref, computed } from 'vue'
import VueRouter from 'vue-router'
import env from '@/environment'
import NoAccessPage from '@/components/access/NoAccessPage.vue'
import { useContentfulFunctions } from '@/composable/common/contentful'
import i18n from '@/config/i18n'
import { toModernUrl } from '@/shared/routeUtils.js'

import allRoutes from './routes'
const {
  faultRoutes,
  authenticationRoutes,
  serviceRoutes,
  companyRoutes,
  riskManagementRoutes,
  cardFileRoutes,
  personnelRoutes,
  maintenanceRoutes,
  settingsRoutes,
  incidentRegistryRoutes,
  employeeRoutes,
  employerRoutes,
  adminAccountRoutes,
  controlPanelRoutes,
  iFrames
} = allRoutes

import { authService, useAccountService } from '@/services'
import store from '@/store'
import { dispatchV1RouteChanged } from '@/utilities/iframe'

Vue.use(VueRouter)

const routes = [
  {
    path: '/app-version',
    component: () =>
      import(
        /* webpackChunkName: "AppVersionInfoPage" */ `@/views/version-info/AppStatus.vue`
      ),
    productionAvailable: true
  },

  {
    path: '/translations',
    component: () =>
      import(
        /* webpackChunkName: "TranslationsPage" */ `@/views/version-info/Translations.vue`
      ),
    productionAvailable: true
  },

  ...faultRoutes,

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ `@/views/Home.vue`),
    meta: { title: 'meta__title__root_dir' },
    productionAvailable: true
  },

  ...authenticationRoutes,
  ...serviceRoutes,
  ...companyRoutes,
  ...riskManagementRoutes,
  ...cardFileRoutes,
  ...personnelRoutes,
  ...maintenanceRoutes,
  ...settingsRoutes,
  ...incidentRegistryRoutes,
  ...employeeRoutes,
  ...employerRoutes,
  ...adminAccountRoutes,
  ...controlPanelRoutes,
  ...iFrames
]

const filterAvailableRoutes = (routes, environment) =>
  environment === 'prod' || environment === 'test'
    ? routes.filter(
        route =>
          route.meta?.requiresAccessRights != null || route.productionAvailable
      )
    : routes

const mapBreadcrumbsHrefToModernUrl = routes =>
  routes.map(route => {
    if (route.meta?.breadcrumbs?.length > 0) {
      route.meta.breadcrumbs.href = route.meta.breadcrumbs.map(breadcrumb => {
        if (breadcrumb.href == null) return
        breadcrumb.href = toModernUrl(breadcrumb.href)
        return breadcrumb
      })

      // Map children href to modern url if it exists and if the route has children routes (recursive)
      if (route?.children?.length > 0)
        route.children = mapBreadcrumbsHrefToModernUrl(route.children)
    }
    return route
  })

const prepareRoutes = (routes, environment) => {
  const onlyAvailableInProduction = filterAvailableRoutes(
    routes,
    env.VUE_APP_ENVIRONMENT
  )
  const withModernUrl = mapBreadcrumbsHrefToModernUrl(onlyAvailableInProduction)
  return withModernUrl
}

const checkUserAuthentication = async () => {
  if (!(await authService.isAuthenticated())) {
    await authService.login()
  }
}

const checkUserAccessRights = async (to, next) => {
  await store.dispatch('featureFlags/fetchFeatureFlags')
  // Check for feature flags active if the route has been flagged
  for (const route of to.matched) {
    const { requiresAccessRights, fallbackPath } = route.meta
    if (requiresAccessRights != null) {
      const hasPermission = store.getters['featureFlags/isAnyFeatureEnabled'](
        route.meta.requiresAccessRights
      )

      if (!hasPermission) {
        if (fallbackPath != null) window.location.replace(fallbackPath)
        else next({ name: 'Home' })
        return
      }
    }
    // Check if user has access to the matched routes, if not change component (not hero) to denied access page
    // If the route does not have a menuAccess property, it is available to all users and it does not need to be checked
    else
      await Promise.all(
        to.matched.map(async matchedRoute => {
          if (matchedRoute.meta.menuAccess == undefined) return
          const accessRights = await getAccessRights(
            matchedRoute.meta.menuAccess
          )
          if (!accessRights.read) matchedRoute.components.default = NoAccessPage
        })
      )
  }
}

const router = new VueRouter({
  mode: 'history',
  base: env.VUE_APP_BASE_URL,
  routes: prepareRoutes(routes, env.VUE_APP_ENVIRONMENT),
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is populated if browser navigation is used
    // If not, scroll to top
    if (savedPosition) return savedPosition
    else return { x: 0, y: 0 }
  }
})

const { getAccessRights } = useAccountService()

router.beforeEach(async (to, _, next) => {
  await checkUserAuthentication()
  await checkUserAccessRights(to, next)

  // Fetch init data if not already fetched
  if (!store.state.initDataFetched) {
    await store.dispatch('setContentfulInit', i18n)
    await store.dispatch('getInitData')
  }

  dispatchV1RouteChanged(to.path)

  next()
  store.dispatch('setAppLoaded', true)
})

router.afterEach(async () => {
  //Load eventually contentful changes
  await useContentfulFunctions().loadDeltaData(i18n)
})

const _router = ref(router)

export function useRouter() {
  return _router.value
}

export function useRoute() {
  return computed(() => _router.value.currentRoute)
}

// Do not use this function in a composable, use useRoute() instead
export default router
