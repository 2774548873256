import { reactive, toRefs } from 'vue'
import { httpMethods, requestHandlerWithState } from './requestHandler'
import { addNewItem } from '@/utilities/table'

const state = reactive({
  contacts: [],
  liteContacts: [],
  contact: null,
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  writeAccess: false,
  readAccess: false
})

export const useContactService = () => {
  const mapToLiteContact = contact => ({
    id: contact.id,
    hms_konto_id: contact.hms_konto_id,
    name: contact.name,
    deleted: contact.deleted
  })

  const getSelectableLiteContacts = async () => {
    const url = '/hr/api/contact/getSelectableLiteContacts'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.contacts = result.data
  }

  const saveContact = async contactDto => {
    const url = '/hr/api/contact/'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      contactDto
    )
    if (result.success) {
      state.contact = result.data
      addNewItem({ ...result.data }, state.contacts)
      addNewItem({ ...mapToLiteContact(result.data) }, state.liteContacts)
    }

    return result
  }

  const getAllLiteContacts = async () => {
    const url = `hr/api/contact/getAllLiteContacts`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.liteContacts = result.data
  }

  return {
    state: toRefs(state),
    getSelectableLiteContacts,
    saveContact,
    getAllLiteContacts
  }
}
