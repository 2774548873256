const adminAccountRoutes = [
  {
    path: '/admintools/overview',
    name: 'AdminAccountOverview',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AccountsOverviewHero" */ `@/views/admin-tools/account-overview/AccountsOverviewHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "AccountsOverview" */ `@/views/admin-tools/account-overview/AccountsOverview.vue`
        )
    },
    meta: {
      title: 'meta__title__account_admin',
      menuAccess: 'KONTOADMIN',
      contentful: {
        category: '3WOLdc8ri8m81EGbWgMW5r',
        pageHelp: ''
      },
      breadcrumbs: [
        {
          text: 'Account Management',
          string: 'admin__dashboard_info',
          disabled: false,
          href: '/admintools/overview'
        },
        {
          text: 'Accounts',
          string: 'admin__accounts_info',
          disabled: false,
          href: '/admintools/overview'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/admintools/overview/edit',
    name: 'AdminAccountEdit',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AccountEditHero" */ `@/views/admin-tools/account-edit/AccountEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "AccountEdit" */ `@/views/admin-tools/account-edit/AccountEdit.vue`
        )
    },
    meta: {
      title: 'meta__title__admin_account_details',
      menuAccess: 'KONTOADMIN',
      contentful: {
        category: '6l9AcsRe9kyNQpDWk2NO2k',
        pageHelp: ''
      },
      breadcrumbs: [
        {
          text: 'Account Management',
          string: 'admin__dashboard_info',
          disabled: false,
          href: '/admintools/overview'
        },
        {
          text: 'Accounts',
          string: 'admin__accounts_info',
          disabled: false,
          href: '/admintools/overview'
        },
        {
          text: 'Account Details',
          string: 'admin__account_details_info',
          disabled: false,
          href: '/admintools/overview/edit'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/admintools/supportaccess',
    name: 'AdminAccountSupportAccess',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "SupportAccessOverviewHero" */ `@/views/admin-tools/support-access/SupportAccessOverviewHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "SupportAccessOverview" */ `@/views/admin-tools/support-access/SupportAccessOverview.vue`
        )
    },
    meta: {
      title: 'admin_support_access__meta__title',
      menuAccess: 'KONTOADMIN',
      contentful: {
        category: 'hOvWFtTbrnFj1T8B3gT9S',
        pageHelp: ''
      },
      breadcrumbs: [
        {
          text: 'Account Management',
          string: 'admin__dashboard_info',
          disabled: false,
          href: '/admintools/supportaccess'
        },
        {
          text: 'Support Access',
          string: 'admin_support_access__meta__title',
          disabled: false,
          href: '/admintools/supportaccess'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/admintools/supportusers',
    name: 'AdminAccountSupportUsers',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "SupportUsersOverviewHero" */ `@/views/admin-tools/support-users/SupportUsersOverviewHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "SupportUsersOverview" */ `@/views/admin-tools/support-users/SupportUsersOverview.vue`
        )
    },
    meta: {
      title: 'admin_support_users__meta_title',
      menuAccess: 'KONTOADMIN',
      contentful: {
        category: '6NlBvFwyvgUS35TO4BvoVQ',
        pageHelp: ''
      },
      breadcrumbs: [
        {
          text: 'Account Management',
          string: 'admin__dashboard_info',
          disabled: false,
          href: '/admintools/supportusers'
        },
        {
          text: 'Support Users',
          string: 'admin_support_access__meta__title',
          disabled: false,
          href: '/admintools/supportusers'
        }
      ]
    },
    productionAvailable: true
  }
]
export default adminAccountRoutes
