import companyRoutes from './company'
import riskManagementRoutes from './risk-management'
import cardFileRoutes from './card-file'
import personnelRoutes from './personnel'
import serviceRoutes from './services'
import authenticationRoutes from './authentication'
import faultRoutes from './faults'
import maintenanceRoutes from './maintenance'
import settingsRoutes from './settings'
import employeeRoutes from './employee/employee'
import employerRoutes from './employer/employer.js'
import incidentRegistryRoutes from './incidentRegistry'
import adminAccountRoutes from './admin-tools'
import controlPanelRoutes from './control-panel'
import iFrames from './iframes'

export default {
  companyRoutes,
  riskManagementRoutes,
  cardFileRoutes,
  personnelRoutes,
  serviceRoutes,
  authenticationRoutes,
  faultRoutes,
  maintenanceRoutes,
  settingsRoutes,
  employeeRoutes,
  employerRoutes,
  incidentRegistryRoutes,
  adminAccountRoutes,
  controlPanelRoutes,
  iFrames
}
