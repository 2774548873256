import { reactive, toRefs } from 'vue'
import { httpMethods, requestHandlerWithState } from './requestHandler'
import { addNewItem } from '@/utilities/table'
import { removeItemAtIndex } from '../utilities/arrayUtilities'

const state = reactive({
  riskAssessments: [],
  riskAssessment: null,
  riskAssessmentThemes: [],
  riskAssessmentTemplates: [],
  risks: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  writeAccess: false,
  readAccess: false
})

export const useRiskAssessmentService = () => {
  const loadRiskAssessments = async () => {
    const url = '/hse/api/riskassessment/getriskassessments'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.riskAssessments = result.data
  }

  const loadRiskAssessment = async id => {
    const url = `/hse/api/riskassessment/getriskassessmentbyid/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.riskAssessment = result.data
  }

  const saveRiskAssessment = async riskAssessmentDto => {
    const url = '/hse/api/riskassessment/saveriskassessment'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      riskAssessmentDto
    )

    if (result.data.success) {
      state.riskAssessment = result.data
      addNewItem({ ...result.data }, state.riskAssessments)
    }

    return result
  }

  const saveRisk = async riskDto => {
    const url = '/hse/api/riskassessment/saverisk'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      riskDto
    )

    if (result.data.success) {
      state.risks = result.data
      addNewItem({ ...result.data }, state.risks)
    }

    return result
  }

  const deleteRiskAssessment = async id => {
    let dto = {
      'ids': []
    }
    dto.ids.push(id)
    const url = `/hse/api/riskassessment/deleteriskassessments`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      dto
    )

    if (result.success) removeItemAtIndex(state.riskAssessments, id)

    return result
  }

  const getRiskAssessmentThemes = async () => {
    const url = 'hse/api/riskassessment/report/themes'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.riskAssessmentThemes = result.data
  }

  const getRiskAssessmentTemplates = async account_id => {
    const url = `/hse/api/riskassessment/getriskassessmenttemplates/${account_id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.riskAssessmentTemplates = result.data
  }

  return {
    state: toRefs(state),
    loadRiskAssessments,
    loadRiskAssessment,
    saveRiskAssessment,
    deleteRiskAssessment,
    getRiskAssessmentThemes,
    getRiskAssessmentTemplates,
    saveRisk
  }
}
