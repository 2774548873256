import { reactive, toRefs } from 'vue'
import avonovaAPI from '@/config/axios'
import { useLogger } from '@/shared/logger'
const log = useLogger('departmentservice')
import {
  isSuccessResponse,
  requestStarted,
  requestFinished,
  requestFailed
} from '@/utilities/httpUtilities'
import store from '@/store'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

// look into transforming departments array to a set instead for easy O(1) lookup rather than O(n)
// using departmentId as key in object.
// department = departments.find(d => d.id === departmentId)
// vs
// department = departments[departmentId]

const state = reactive({
  departments: store?.state.departments || [],
  departmentsTitles: store?.state.departmentsTitles || [],
  departmentsBasedOnLocationAccess: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  lastCreatedDepartmentId: undefined,
  count: {
    moved: 0
  },
  success: true,
  writeAccess: false,
  readAccess: false
})

export const useDepartmentService = () => {
  const getDepartments = async () => {
    const url = '/company/api/company/departments/alldepartments'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) {
      state.departments = result.data
      store.commit('storeApiResponse', {
        item: [...state.departments],
        key: 'departments'
      })

      state.readAccess =
        result.data?.every(department => department.readaccess) ?? false
      state.writeAccess =
        result.data?.every(department => department.writeaccess) ?? false
    } else {
      state.request = requestFailed()
    }
  }
  const getAllDepartmentsTitles = async (includeDeactived = false) => {
    let url = '/company/api/company/departments/allDepartmentsTitles/'
    if (includeDeactived) // include deactivated departments
      url = '/company/api/company/departments/departmentstitles/'

    const response = await requestHandlerWithState(state, httpMethods.GET, url)

    if (response.success) {
      state.departmentsTitles = response.data
      store.commit('storeApiResponse', {
        item: [...state.departmentsTitles],
        key: 'departmentsTitles'
      })
      return response.data
    } else {
      state.request = requestFailed()
    }
  }

  const saveDepartment = async (departmentId, departmentName, isInactive) => {
    let department = state.departments.find(
      department => department.id === departmentId
    )

    const dirtyDepartment = {
      ...department,
      title: departmentName,
      disabled: isInactive
    }

    const url = '/company/api/company/departments'
    const response = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      dirtyDepartment
    )

    if (response.success) {
      const old = {
        title: `${department.title}`,
        disabled: department.disabled
      }

      department.title = departmentName
      department.disabled = isInactive
      return {
        success: true,
        department,
        old
      }
    } else {
      return {
        success: false,
        department
      }
    }
  }

  const addDepartment = async department => {
    const url = '/company/api/company/departments'
    const response = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      department
    )

    if (response.success) {
      state.departments.push(response.data)
      state.lastCreatedDepartmentId = response.data.id
      return {
        success: true,
        department: response.data
      }
    } else {
      state.request = requestFailed()
      return {
        success: false,
        department,
        errors: state.request.errors
      }
    }
  }

  const moveEmployeesToDepartment = async (
    employeeIds,
    moveToDepartmentId,
    currentDepartmentId
  ) => {
    const wrappedEmployeeIds = Array.isArray(employeeIds)
      ? employeeIds
      : [employeeIds] // support single employee

    const payload = {
      empids: wrappedEmployeeIds,
      depid: moveToDepartmentId
    }

    const url = '/company/api/company/departments/moveemployess'
    const response = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      payload
    )

    if (response.success) {
      const currentDepartment = state.departments.find(
        department => department.id === currentDepartmentId
      )
      const count = wrappedEmployeeIds.length

      // subtract move count against current department.
      currentDepartment.totalEmployees =
        currentDepartment.totalEmployees - count
      currentDepartment.possible_to_disable =
        currentDepartment.totalEmployees === 0

      return response
    } else {
      // handle error
      state.request = requestFailed()
    }
  }

  const clearLastCreatedDepartmentId = () => {
    state.lastCreatedDepartmentId = undefined
  }

  const isDepartmentNameAvailable = departmentName => {
    return state.departments.some(
      department => department.title.toLower() === departmentName.toLower()
    )
  }

  const getDepartmentNameById = id => {
    if (typeof id === 'string') id = parseInt(id)
    const department = state.departments.find(d => d.id == id)
    return department?.title
  }

  const findDepartmentIdsByTitle = title => {
    const titleLowered = title.toLowerCase()
    return state.departments
      .filter(d => d.title.toLowerCase().includes(titleLowered))
      .map(item => item.id)
  }

  const getSelectableDepartmentsForLocation = async ({ location, access }) => {
    const url = '/company/api/company/departments/getdepartmentsforlocation'
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      location,
      access
    })

    if (result.success) {
      state.departmentsBasedOnLocationAccess = result.data
    }
    return result
  }

  return {
    state: toRefs(state),
    getDepartments,
    getAllDepartmentsTitles,
    saveDepartment,
    addDepartment,
    moveEmployeesToDepartment,
    getDepartmentNameById,
    findDepartmentIdsByTitle,
    clearLastCreatedDepartmentId,
    getSelectableDepartmentsForLocation
  }
}
