import safetyInspectionDetails from './safetyInspectionDetails'
import { PARENT_BREADCRUMBS } from './constans'
import { FLAG } from '@/constants/featureFlags.js'
import {
  useSafetyInspectionService,
  useDepartmentService,
  useEmployeeService
} from '@/services'
import { ACCESS_RIGHTS } from '@/constants/menuAccess.js'

const overViewGuard = async (to, from, next) => {
  const { getSafetyInspections, setAccessRights } = useSafetyInspectionService()
  const { getDepartments, getAllDepartmentsTitles } = useDepartmentService()
  const { getAllEmployeeNames, getEmployeesByLocationAccess } =
    useEmployeeService()
  const excludedRoutes = [
    'SafetyInspectionsOverviewActive',
    'SafetyInspectionsOverviewCompleted'
  ]

  if (from.name == null || !excludedRoutes.includes(from.name)) {
    await getSafetyInspections()
    await getDepartments()
    await getAllDepartmentsTitles(true)
    await getAllEmployeeNames()

    await getEmployeesByLocationAccess({
      location: 'EDITVERNERUNDE',
      access: 'READ_WRITE_ACCESS'
    })
  }

  next()
}

export default {
  path: '/incidentregistry/safety-inspections',
  name: 'SafetyInspections',
  components: {
    default: () => import(`@/views/incidentRegistry/safetyInspections`)
  },
  meta: {
    menuAccess: ACCESS_RIGHTS.EDIT_VERNERUNDE,
    title: 'menu__item__safety_inspections',
    contentful: {
      pageHelp: ''
    },
    breadcrumbs: PARENT_BREADCRUMBS
  },
  productionAvailable: true,
  redirect: { name: 'SafetyInspectionsOverviewActive' },
  beforeEnter(to, from, next) {
    const { setAccessRights } = useSafetyInspectionService()

    setAccessRights().then(() => next())
  },
  children: [
    {
      path: 'active',
      name: 'SafetyInspectionsOverviewActive',
      beforeEnter: overViewGuard,
      components: {
        hero: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionsHero.vue`
          ),
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/views/SafetyInspections.vue`
          )
      }
    },
    {
      path: 'completed',
      name: 'SafetyInspectionsOverviewCompleted',
      beforeEnter: overViewGuard,
      components: {
        hero: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionsHero.vue`
          ),
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/views/SafetyInspections.vue`
          )
      }
    },
    {
      path: 'templates',
      name: 'SafetyInspectionTemplates',
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/views/SafetyInspectionTemplates.vue`
          )
      },
      children: [
        {
          path: '',
          name: 'SafetyInspectionTemplatesOverview',
          components: {
            hero: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionsHero.vue`
              ),
            default: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/safetyInspectionTemplates/SafetyInspectionTemplatesOverview.vue`
              )
          },
          meta: {
            breadcrumbs: [...PARENT_BREADCRUMBS]
          }
        },
        {
          path: 'new',
          name: 'SafetyInspectionTemplateDetailsAddNew',
          components: {
            hero: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionTemplateDetailsAddNewHero.vue`
              ),
            default: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/safetyInspectionTemplates/SafetyInspectionTemplateDetailsAddNew.vue`
              )
          },
          meta: {
            breadcrumbs: [
              ...PARENT_BREADCRUMBS,
              {
                string:
                  'safetyInspection__create_new_safety_inspection_template',
                disabled: true
              }
            ]
          }
        },
        {
          path: ':id',
          name: 'SafetyInspectionTemplateDetailsView',
          components: {
            hero: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionTemplateDetailsHero.vue`
              ),
            default: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/safetyInspectionTemplates/SafetyInspectionTemplateDetailsView.vue`
              )
          },
          beforeEnter(to, from, next) {
            const { getSafetyInspectionTemplatesById } =
              useSafetyInspectionService()
            const existingMeta = to.meta || {}

            getSafetyInspectionTemplatesById(to.params.id).then(response => {
              to.params.data = response
              if (to.params.data.writeAccess)
                next({
                  name: 'SafetyInspectionTemplateDetailsEdit',
                  params: { id: to.params.id, data: response }
                })
              next()
            })
          },
          meta: {
            breadcrumbs: [
              ...PARENT_BREADCRUMBS,
              {
                text: '',
                disabled: true
              }
            ]
          }
        },
        {
          path: ':id/edit',
          name: 'SafetyInspectionTemplateDetailsEdit',
          components: {
            hero: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionTemplateDetailsHero.vue`
              ),
            default: () =>
              import(
                `@/views/incidentRegistry/safetyInspections/safetyInspectionTemplates/SafetyInspectionTemplateDetailsEdit.vue`
              )
          },
          beforeEnter(to, from, next) {
            const { getSafetyInspectionTemplatesById } =
              useSafetyInspectionService()

            if (to.params.data) next()

            getSafetyInspectionTemplatesById(to.params.id)
              .then(response => {
                to.params.data = response
              })
              .finally(() => next())
          },
          meta: {
            breadcrumbs: [
              ...PARENT_BREADCRUMBS,
              {
                text: '',
                disabled: true
              }
            ]
          }
        }
      ]
    },
    {
      path: ':id',
      name: 'SafetyInspectionsOverviewId',
      components: {
        hero: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionOverviewHero.vue`
          ),
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/views/SafetyInspectionSummaryDetails.vue`
          )
      },
      props: true,
      meta: {
        title: 'menu__item__safety_inspections',
        contentful: {
          pageHelp: ''
        },
        breadcrumbs: [...PARENT_BREADCRUMBS]
      }
    },
    safetyInspectionDetails
  ]
}
