import avonovaAPI from '../config/axios'
import router from '../router'
import { useContentfulFunctions } from '@/composable/common/contentful'
import { useFilesService } from '@/services/files-service'
import { useAccountService } from '@/services/account/account-service.js'

const actions = {
  getAuthorizations() {
    return avonovaAPI.get('/api/authorization')
  },
  async getInitData({ commit, dispatch }) {
    const { getAccountLogo } = useFilesService()
    const { getFeatureFlags } = useAccountService()
    return Promise.all([
      avonovaAPI.get('/common/api/accounts/init'),
      getFeatureFlags()
    ])
      .then(([res, flags]) => {
        commit('setInitData', res.data)
        commit('featureFlags/SET_FEATURE_FLAGS', flags, { root: true })
        dispatch('authUser/updateAuthUser', res.data, { root: true })
      })
      .then(async () => {
        getAccountLogo()
          .then(logo => {
            if (logo) commit('setCompanyLogo', URL.createObjectURL(logo))
          })
          .catch(() => {})
      })
  },
  async setContentfulInit(_, i18n) {
    await useContentfulFunctions().initDataFromCache(i18n)
  },
  getHandbookInfo({ commit }) {
    avonovaAPI.get('/directives/api/handbook/readhandbookinfo').then(res => {
      commit('setHandbookInfo', res.data)
    })
  },
  executeJwtAuthenticationService(_context, userData) {
    return avonovaAPI.post('/common/api/authenticate', userData)
  },
  redirectToPathName(_context, path) {
    if (path && parseInt(path.id) > 0) {
      router.push({ name: path.url, params: { id: path.id } })
    } else {
      router.push({ name: path.url })
    }
  },
  getCompanyInfo({ commit }) {
    return avonovaAPI.get('/company/api/company').then(response => {
      commit('storeCompanyInfo', response.data)
    })
  },
  logError(_context, error) {
    return avonovaAPI.post('/monolith/api/errorlog', error)
  },
  newNotification({ commit }, notification) {
    commit('newNotification', notification)
  },
  popNotification({ state }) {
    return state.notifications.pop()
  },
  setAppLoaded({ commit }, value) {
    commit('setAppLoaded', value)
  }
}
export default actions
