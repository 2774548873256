import env from '@/environment'
import * as Contentful from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { twoDatesMinuteDifference } from '@/composable/common/dates.js'
import { reactive, toRefs } from 'vue'
import {
  updateI18nLocaleMessages,
  findMissingTranslations
} from './contentful/updateI18nLocaleMessages'
import {
  defaultLanguage,
  renderOptions,
  cacheName
} from './contentful/contentfulConfig'
import { useLogger } from '../../shared/logger'
/*
  The initial-sync.json file is generated by <project>/contentful-init-sync.js.
  It contains a complete state of content entries from Contentful.
  The 'contentful-init-sync' script in package.json is set to run with each build and serve script.
 */
const contentfulPrimerFile = () =>
  import(/* webpackChunkName: "cf-init" */ '@/contentful/initial-sync.json')

const state = reactive({
  contentfulClient: false,
  contentful: {
    entries: []
  }
})

export const useContentfulFunctions = () => {
  const logger = useLogger('contentful')

  const getHtmlString = source => documentToHtmlString(source, renderOptions)

  const getContentfulEntryFromRoot = id => {
    if (!id) return false
    const getEntry = state.contentful.entries.filter(f => f.sys.id === id)
    if (!getEntry) return false
    else return getEntry
  }

  const getPageContentAsHtmlByEntryId = (entryId, language) => {
    const entry = getPageContent(entryId, language)
    const html = getHtmlString(entry.content)
    const youtube = entry.videoId
    return {
      title: entry.title,
      html,
      youtube
    }
  }

  const getContentByType = contentType => {
    if (!state.contentfulClient) createClient()

    const items = state.contentful.entries.filter(
      entry => entry.sys.contentType.sys.id === contentType
    )

    return items
  }

  const getContentById = id => {
    if (!state.contentfulClient) createClient()

    return state.contentful.entries.find(entry => entry.sys.id === id)
  }

  const getPageContent = (pageHelpSysId, currentLanguage) => {
    if (pageHelpSysId === undefined) return

    const entryId = pageHelpSysId //this.$router.currentRoute.meta.contentful.pageHelp

    const data = state.contentful.entries
      .filter(f => f.sys.id === entryId)
      .map(pageHelp => {
        let getContent =
          getContentfulEntryFromRoot(
            pageHelp?.fields?.content[defaultLanguage][0]?.sys.id
          ) || []
        let getContentTwo =
          getContentfulEntryFromRoot(
            pageHelp?.fields?.content[defaultLanguage][1]?.sys.id
          ) || []

        const both = getContent.concat(getContentTwo)
        const findContent = both.find(f => f.sys.contentType.sys.id === 'help')
          ?.fields.text[currentLanguage]
        const findVideo = both.find(f => f.sys.contentType.sys.id === 'youtube')
          ?.fields.embedId[currentLanguage]

        return {
          title: pageHelp.fields.title[currentLanguage],
          content: findContent || null,
          videoId: findVideo || null
        }
      })
    return {
      title: data[0]?.title ?? '',
      content: data[0]?.content ?? '',
      videoId: data[0]?.videoId ?? ''
    }
  }

  const createClient = () => {
    state.contentfulClient = Contentful.createClient({
      space: env.VUE_APP_CTF_SPACE_ID,
      environment: env.VUE_APP_CTF_ENVIRONMENT_ID,
      accessToken: env.VUE_APP_CTF_CDA_ACCESS_TOKEN
    })
  }

  const getDataFromCache = async () => {
    const cacheStorage = await caches.open(cacheName)
    const cachedResponse = await cacheStorage.match(cacheName)

    if (!cachedResponse || !cachedResponse.ok) {
      return false
    }

    return await cachedResponse.json()
  }

  const putDataToCache = async data => {
    const options = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      data: JSON.stringify(data),
      status: 200
    }
    const jsonResponse = new Response(options.data, options)
    await caches.open(cacheName).then(c => {
      return c.put(cacheName, jsonResponse)
    })
  }

  const getContentfulSyncToken = () =>
    localStorage.getItem('contentfulSyncToken')

  const setContentfulSyncToken = data =>
    localStorage.setItem('contentfulSyncToken', data.nextSyncToken)

  const clearContentfulSyncToken = () =>
    localStorage.removeItem('contentfulSyncToken')

  const getLastSyncTime = () => {
    const lastSyncDateString = localStorage.getItem('lastContentfulSync')
    if (lastSyncDateString !== null) {
      return new Date(lastSyncDateString).getTime()
    }
    return null
  }

  const updateLastSyncTime = () =>
    localStorage.setItem('lastContentfulSync', new Date().toString())

  const clearLastSyncTime = () => localStorage.removeItem('lastContentfulSync')

  const getContentfulData = async (delta = false) => {
    let response

    if (delta) {
      logger.debug('getContentfulData delta update')
      response = await state.contentfulClient.sync({
        nextSyncToken: getContentfulSyncToken()
      })
    } else {
      logger.debug('getContentfulData initial')
      response = await state.contentfulClient.sync({
        initial: true,
        resolveLinks: false,
        type: 'Entry'
      })
    }

    return JSON.parse(response.stringifySafe())
  }

  const getMissingTranslations = async () => {
    logger.debug('getMissingTranslations')
    if (!state.contentfulClient) createClient()

    const freshData = await getContentfulData()
    const missingTranslations = findMissingTranslations(freshData)

    return missingTranslations
  }

  /**
   * Load initial Contentful data from web browser cache, or from Contentful API (or primer file) if not found in cache.
   *
   * @param i18n
   * @return {Promise<any>}
   */
  const initDataFromCache = async i18n => {
    const primerData = await contentfulPrimerFile()
    const lastToken = localStorage.getItem('lastPrimerToken')
    let cachedData = await getDataFromCache().catch(error => {})

    if (
      primerData.nextSyncToken !== lastToken ||
      !cachedData ||
      !cachedData.nextSyncToken ||
      getContentfulSyncToken() === null
    ) {
      // primer file is new
      logger.debug('initDataFromCache new primer file')
      // set primer token.
      localStorage.setItem('lastPrimerToken', primerData.nextSyncToken)
      setContentfulSyncToken(primerData)

      cachedData = primerData
    }

    // Enrich cached data with latest delta data from Contentful API
    try {
      logger.debug('load delta data from contentful api.')
      const deltaData = await getContentfulData(true)
      updateLastSyncTime()
      setContentfulSyncToken(deltaData)

      if (deltaData.entries.length > 0) {
        // We got new delta data (Contentful has new entries since the primer file was created)!
        // Add the new delta data to the cached data
        await mergeNewDataWithTargetData(deltaData, cachedData)
      }
    } catch (error) {
      //console.error("Error getting initial sync from Contentful, falling back to primer-file! Error: " + error)
      // eager log to Sentry.
    }

    await putDataToCache(cachedData).catch(error => {})

    updateI18nLocaleMessages(cachedData, i18n)
    state.contentful.entries = cachedData.entries
  }

  /**
   * Load added or changed entries (if any) from Contentful since previous load sync time.
   * Uses Contentful sync token to track checkpoint for last delta load.
   */
  const loadDeltaData = async i18n => {
    // Check if it's time to get new delta data
    const lastSyncTime = getLastSyncTime()
    if (lastSyncTime !== null) {
      logger.debug('loadDeltaData syncDate found!')
      const now = new Date().getTime()
      if (twoDatesMinuteDifference(now, lastSyncTime) < 1) {
        logger.debug('loadDeltaData less than 1 min. Skip out')
        // Less than 1 minute since last sync time, need to wait a bit more
        return
      }
    }

    // It's time to check for new delta data!
    try {
      const deltaData = await getContentfulData(true)

      updateLastSyncTime()
      setContentfulSyncToken(deltaData)

      if (deltaData.entries.length > 0) {
        // We got new delta data!
        // Update cached data with new entries from the delta data
        let currentData = await getDataFromCache().catch(error => {})

        await mergeNewDataWithTargetData(deltaData, currentData)

        await putDataToCache(currentData).catch(error => {})
        updateI18nLocaleMessages(currentData, i18n)
        return currentData
      }
    } catch (error) {
      //console.error("Error getting delta data from Contentful, initiating full re-init... error: " + error)
      clearContentfulSyncToken()
      clearLastSyncTime()
      return await initDataFromCache(i18n)
    }
  }

  /**
   * Merges sourceData Contentful entries into targetData. Replaces existing targetData entries where key (sys.id) is equal.
   */
  const mergeNewDataWithTargetData = async (sourceData, targetData) => {
    sourceData.entries.forEach(sourceEntry => {
      // Check if entry already exists in target
      const foundIndex = targetData.entries.findIndex(
        targetEntry => targetEntry.sys.id === sourceEntry.sys.id
      )
      if (foundIndex < 0) {
        // Did not find existing entry in target, add source entry as new entry
        targetData.entries.push(sourceEntry)
      } else {
        // Found existing entry in target, replace it with the source entry
        targetData.entries[foundIndex] = sourceEntry
      }
    })
  }

  const getAsset = async assetId => {
    if (!state.contentfulClient) createClient()

    return await state.contentfulClient.getAsset(assetId)
  }

  return {
    state: toRefs(state),
    createClient,
    initDataFromCache,
    loadDeltaData,
    updateI18nLocaleMessages,
    getPageContentAsHtmlByEntryId,
    getMissingTranslations,
    getContentByType,
    getContentById,
    getHtmlString,
    getAsset
  }
}
