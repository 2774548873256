import Highcharts from 'highcharts'
import HighchartsExportingInit from 'highcharts/modules/exporting'
import HighchartsFullscreenInit from 'highcharts/modules/full-screen'
import HighchartsAdaptToLegendInit from './highcharts-adapt-chart-to-legend'

const options = {
  credits: { enabled: false },
  chart: { height: 400 },
  title: {
    align: 'left',
    margin: 50,
    style: {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '22px',
      color: '#000000'
    }
  },
  subtitle: {
    align: 'left',
    style: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#000000'
    }
  },
  xAxis: {
    labels: {
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        color: '#444444'
      }
    },
    title: {
      margin: 24,
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '16px',
        color: '#444444'
      }
    }
  },
  yAxis: {
    tickmarkPlacement: 'on',
    labels: {
      align: 'left',
      reserveSpace: true,
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        color: '#444444'
      }
    },
    title: {
      margin: 24,
      style: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '16px',
        color: '#444444'
      }
    }
  },
  legend: {
    padding: 0,
    margin: 42,
    itemMarginTop: 12,
    symbolWidth: 42,
    symbolPadding: 32,
    adjustChartSize: true,
    layout: 'vertical',
    verticalAlign: 'bottom',
    align: 'center left',
    navigation: {
      enabled: false
    },
    title: {
      style: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000'
      }
    },
    itemStyle: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '130%',
      color: '#000000',
      textAlign: 'left'
    }
  },
  tooltip: {
    enabled: false,
    backgroundColor: '#ffffff',
    style: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#000000'
    }
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  plotOptions: {
    series: {
      lineWidth: 2.5,
      pointWidth: 66
    }
  }
}

Highcharts.setOptions(options)
HighchartsExportingInit(Highcharts)
HighchartsFullscreenInit(Highcharts)
HighchartsAdaptToLegendInit(Highcharts)

export default Highcharts
