import { getRouteHrefByName, isLegacy } from '../routeHelper'

const incidentRegistry = [
  {
    permissionName: 'EDITTILTAK',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__action_plan',
    url: getRouteHrefByName('ActionPlan'),
    isLegacy: isLegacy(getRouteHrefByName('ActionPlan'))
  },
  {
    permissionName: 'EDITVERNERUNDE',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__safety_inspections',
    url: getRouteHrefByName('SafetyInspections'),
    isLegacy: isLegacy(getRouteHrefByName('SafetyInspections'))
  },
  {
    permissionName: 'EDITHENDELSE',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__incidents',
    url: '/incidentregistry/incidents',
    isLegacy: false
  },

  {
    permissionName: 'EDITTILSYNSRAPPORT',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__inspection_report',
    url: getRouteHrefByName('Inspections'),
    isLegacy: isLegacy(getRouteHrefByName('Inspections'))
  },
  {
    permissionName: 'EDITREVISJON',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__audit_reports',
    url: '/hendelsesregister/revisjon/revisions.xhtml',
    isLegacy: true
  },
  {
    permissionName: 'RAPPORTER',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__report_incident_registry',
    url: '/hendelsesregister/rapporter/oversikt.xhtml',
    isLegacy: true
  },
  {
    permissionName: 'KONSERNRAPPORT',
    parent: 'EVENTCONTAINER',
    menuItemText: 'menu__item__concern_report',
    url: '/konsern/konsernrapporter.xhtml',
    isLegacy: true
  }
]

export default incidentRegistry
