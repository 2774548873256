import avonovaAPI from '@/config/axios'
import * as Sentry from '@sentry/vue'
import { isSomething } from '@/utilities/conditionalUtilities'
import {
  isSuccessResponse,
  requestStarted,
  requestFinished,
  requestFailed
} from '@/utilities/httpUtilities'
import { useSnackbar } from '../shared/snackbar'
import { useLogger } from '../shared/logger'
const logger = useLogger('requestHandler')

export const httpMethods = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
}

const executeRequest = async (method, url, payload) =>
  isSomething(payload)
    ? await avonovaAPI[method](url, payload)
    : await avonovaAPI[method](url)

const createResult = response => ({
  success: true,
  data: response.data,
  hasContent: response.status !== 204
})

export const executeRequestWithCustomHeaders = async (
  method,
  url,
  payload,
  headers
) =>
  isSomething(payload)
    ? await avonovaAPI[method](url, payload, headers)
    : await avonovaAPI[method](url, headers)

const createErrorResult = error => ({
  success: false,
  statusCode: error?.response?.status,
  data: error?.response?.data
})

const errorNotification = error => {
  switch (error?.response?.status) {
    case 500:
      useSnackbar('common__http_error_500', 'error')
      break
    case 502:
      useSnackbar('common__http_error_502', 'error')
      break
    case 503:
      useSnackbar('common__http_error_503', 'error')
      break
  }

  Sentry.configureScope(scope => {
    if (error?.response?.status)
      scope.setTag('http-status-code', error.response.status)

    if (isSomething(error?.response?.headers['x-amzn-trace-id']))
      scope.setTag('x-amzn-trace-id', error.response.headers['x-amzn-trace-id'])

    Sentry.captureException(error)
  })
}

export const requestHandler = async (method, url, payload, headers = null) => {
  return await requestHandlerWithState(
    null, // empty state
    method,
    url,
    payload,
    headers
  )
}

export const requestHandlerWithState = async (
  state,
  method,
  url,
  payload,
  headers = null
) => {
  if (isSomething(state)) state.request = requestStarted()
  let response

  try {
    response = isSomething(headers)
      ? await executeRequestWithCustomHeaders(method, url, payload, headers)
      : await executeRequest(method, url, payload)

    if (isSuccessResponse(response)) {
      if (isSomething(state)) state.request = requestFinished()
      return createResult(response)
    } else throw Error(`Request: ${method.toUpper()} ${url} failed.`)
  } catch (error) {
    logger.error(error?.stack ?? error) // log error stack or error as a string
    if (isSomething(state)) state.request = requestFailed(error)
    errorNotification(error)
    return createErrorResult(error)
  }
}
