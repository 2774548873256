<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    attach="main"
    :width="dialogWidth"
    hide-overlay
    content-class="chat-dialog elevation-0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!isMobile($vuetify)"
        title="Chat"
        class="chat-button elevation-0"
        :style="{ bottom: bottomPlacement }"
        color="accent"
        fab
        width="60"
        height="60"
        v-bind="attrs"
        v-on="on"
        @click="toggleChat()"
      >
        <v-icon color="black">
          <template v-if="!dialog">chat_bubble_outline</template>
          <template v-else>close</template>
        </v-icon>
      </v-btn>
      <div v-else class="mx-3 d-flex justify-end">
        <v-btn
          v-if="!chatLoaded"
          small
          fab
          color="accent"
          width="50"
          height="50"
          @click="toggleChat()"
          ><v-icon color="black" size="20">chat_bubble_outline</v-icon></v-btn
        >
      </div>
      <script
        type="application/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/7959634.js"
      ></script>
    </template>
    <div class="chat">
      <a
        v-if="accessBtn"
        href="/control-panel/avonova-access"
        target="_blank"
        class="avonova-access-btn text-decoration-none"
        :style="{ bottom: bottomPlacement }"
      >
        <v-btn color="black" rounded width="159" height="43">
          <v-icon color="accent" class="mr-2" size="16">screen_share</v-icon>
          <span class="accent--text text--tiny">{{
            $t('help__avonova_access')
          }}</span>
          <v-icon color="accent" class="mx-1" @click.prevent="accessBtn = false"
            >close</v-icon
          >
        </v-btn>
      </a>
    </div>
  </v-dialog>
</template>
<script>
import { responsiveFunctions } from '@/composable/common/responsive.js'
export default {
  name: 'Chat',
  props: ['loaded'],
  setup() {
    const { isMobile } = responsiveFunctions()
    return {
      isMobile
    }
  },
  data() {
    return {
      dialog: false,
      chatStarted: false,
      hasBottomNav: true,
      accessBtn: true
    }
  },
  computed: {
    dialogWidth() {
      return this.$vuetify.breakpoint.width > 456
        ? 456
        : this.$vuetify.breakpoint.width
    },
    chatIframe() {
      if (document.getElementById('hubspot-messages-iframe-container')) {
        return document
          .getElementById('hubspot-messages-iframe-container')
          .getElementsByTagName('iframe')[0]
      }
      return false
    },
    hubSpotConversations() {
      return window.HubSpotConversations
    },
    chat() {
      return this.hubSpotConversations
        ? this.hubSpotConversations.widget.status()
        : false
    },
    chatLoaded() {
      if (this.chat) {
        return this.chat.loaded
      }
      return false
    },
    page() {
      return this.$route.path
    },
    bottomPlacement() {
      if (this.hasBottomNav) {
        return '7rem'
      }
      return '1rem'
    }
  },
  created() {
    if (window.HubSpotConversations) {
      window.hsConversationsSettings = {
        loadImmediately: false
      }
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsSettings = {
            loadImmediately: false
          }
        }
      ]
    }
  },
  mounted() {
    window.HubSpotConversations?.on('conversationStarted', payload => {
      this.chatStarted = true
    })
    setTimeout(() => {
      this.hasBottomNav = Boolean(
        document.getElementsByClassName('bottom-navigation-bar').length
      )
    }, 500)
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal != oldVal) {
        this.accessBtn = true
        setTimeout(() => {
          this.hasBottomNav = Boolean(
            document.getElementsByClassName('bottom-navigation-bar').length
          )
        }, 500)
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.accessBtn = true
    },
    toggleChat() {
      this.accessBtn = true
      if (!this.chatLoaded) {
        this.loadChat()
      }
      if (this.dialog) {
        this.closeChat()
      } else {
        this.openChat()
      }
    },
    loadChat() {
      window.HubSpotConversations?.widget.load({ 'widgetOpen': true })
      this.openChat()
    },
    removeChat() {
      if (window.HubSpotConversations?.widget && !this.chatStarted) {
        window.HubSpotConversations.widget.remove()
      } else if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.close()
      }
    },
    openChat() {
      window.HubSpotConversations.widget.open()
    },
    closeChat() {
      window.HubSpotConversations.widget.close()
    }
  }
}
</script>
<style lang="scss">
.v-btn.chat-button {
  position: fixed;
  right: 1rem;
  z-index: 99;
}
.chat-dialog.v-dialog {
  position: relative;
  .avonova-access-btn {
    position: fixed;
    right: calc(2rem + 56px);
    @media only screen and (max-width: 959px) {
      display: none;
    }
  }
}
.chat {
  position: fixed;
  bottom: 80px;
  right: calc((100vw - 1158px - 32px) / 2);
  overflow-y: visible;
  @media (max-width: 1158px) {
    right: 0;
  }
}
div#hubspot-messages-iframe-container {
  z-index: 98;
  @media (min-width: 600px) {
    bottom: 0 !important;
  }
}
</style>
