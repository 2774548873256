import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sanitizeHtml from 'sanitize-html'
import { DEFAULT_LOCALE } from '@/constants/i18n'
Vue.use(VueI18n)

// TODO: Remove legacy date formats and use Luxon instead
const legacyDateTimeFormats = {
  compact: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  },
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  long: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const dateTimeFormats = {
  'en-GB': legacyDateTimeFormats,
  'nb-NO': legacyDateTimeFormats,
  'sv-SE': legacyDateTimeFormats,
  'pl-PL': legacyDateTimeFormats,
  'fi-FI': legacyDateTimeFormats
}

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: { default: DEFAULT_LOCALE },
  messages: null,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  dateTimeFormats
})

// This custom method handles all HTML-related functionality
// It takes in a key as a parameter and returns a clean string of HTML content
Vue.prototype.$thtml = function (key) {
  return sanitizeHtml(i18n.t(key))
}

export const createIntlLocale = locale => new Intl.Locale(locale)

export default i18n
