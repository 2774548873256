import { reactive, toRefs } from 'vue'
import { followUpDto } from '@/composable/common/dtos/employer/absence/followUpDto.js'
import { httpMethods, requestHandlerWithState } from './requestHandler'

const state = reactive({
  absencesFollowUpOverview: [],
  templateEventList: [],
  selectableEmployees: { otherEmployeeIds: [], employeeIdsWithAbsence: [] },
  followUpItem: {},
  updatedEvent: null,
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useAbsenceFollowUpService = () => {
  const getAbsencesFollowUpOverview = async () => {
    const url = '/hr/api/absenceFollowUp/overview/'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.absencesFollowUpOverview = result.data
    return result
  }

  const getFollowUpById = async id => {
    const url = `/hr/api/absenceFollowUp/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.followUpItem = {
        ...result.data, events: result.data.events?.sort((a, b) => {
          return new Date(a.deadline) - new Date(b.deadline)
        })
      }
    }
    return result
  }

  const resetFollowUpItem = id => {
    state.followUpItem = followUpDto()
    state.followUpItem.employeeId = parseInt(id)
  }

  const updateCourseOfEvents = async () => {
    const url = `/hr/api/absenceFollowUp/updateAbsenceFollowUp/`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      state.followUpItem
    )
    if (result?.success) {
      state.followUpItem = {
        ...result.data, events: result.data.events?.sort((a, b) => {
          return new Date(a.deadline) - new Date(b.deadline)
        })
      }
    }
    return result
  }

  const deleteFollowUpById = async id => {
    const url = `hr/api/absenceFollowUp/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)

    return result.data
  }

  const getTemplateEventList = async firstDayDate => {
    const url = `/hr/api/absenceFollowUp/getTemplateEventList/${firstDayDate}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) updateFollowUpEventList(result.data)
    return result
  }

  const updateFollowUpEventList = eventList => {
    state.followUpItem.events = eventList
  }

  const getSelectableEmployees = async () => {
    const url = '/hr/api/absenceFollowUp/selectableEmployeesForNewForm/'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.selectableEmployees = result.data
    return result
  }

  const completeFollowUpEvent = async (followUpId, eventId) => {
    const url = `/hr/api/absenceFollowUp/completeFollowUpEventFormOverview/${followUpId}/${eventId}`
    return await requestHandlerWithState(state, httpMethods.POST, url, {
      followUpId,
      eventId
    })
  }

  const removeFollowUpEvent = async (followUpId, eventId) => {
    const url = `/hr/api/absenceFollowUp/removeFollowUpEventFormOverview/${followUpId}/${eventId}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      followUpId,
      eventId
    })
    if (result.success) {
      const indexOfEventToBeRemoved = state.followUpItem.events.findIndex(
        event => event.id === eventId
      )
      state.followUpItem.events.splice(indexOfEventToBeRemoved, 1)
    }
    return result
  }

  return {
    state: toRefs(state),
    getAbsencesFollowUpOverview,
    getFollowUpById,
    updateCourseOfEvents,
    getTemplateEventList,
    getSelectableEmployees,
    completeFollowUpEvent,
    removeFollowUpEvent,
    deleteFollowUpById,
    resetFollowUpItem
  }
}
