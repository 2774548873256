const faultRoutes = [
  {
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "NotExistingPage" */ `@/components/access/NotExistPage.vue`
      ),
    meta: { title: 'meta__title__not_found' }
  },
  {
    path: '/denied',
    name: 'Denied',
    component: () =>
      import(
        /* webpackChunkName: "NoAccessPage" */ `@/components/access/NoAccessPage.vue`
      ),
    meta: { title: 'meta__title__error' }
  },
  {
    path: '/no-access',
    name: 'NoAccess',
    component: () => import(/* webpackChunkName: "ApplicationNoAccess" */ `@/components/access/ApplicationNoAccess.vue`),
    meta: {
      title: 'meta__title__loggedin__no_access',
    },
    productionAvailable: true
  }
]

export default faultRoutes
