import { insertAt } from '@/utilities/arrayUtilities'
import { isEmpty, isNone } from './conditionalUtilities'

export const isNewItem = item => {
  if (item.newItem === undefined) return ''

  if (item.newItem) {
    // resets newItem bool to apply transition of bg-color
    setTimeout(() => {
      delete item.newItem
    }, 6500)
    return 'new-item-animation'
  }
}

export const addNewItem = (item, array, i) => {
  item.newItem = true
  insertAt(array, i ?? 0, item)
}

export const addNewItems = (items, array) => {
  const itemsWithNewItem = items.map(e => {
    return { ...e, newItem: true }
  })
  insertAt(array, 0, ...itemsWithNewItem)
}

export const replaceNewItem = (item, array, index) => {
  const newItem = { ...item, newItem: true }
  array.splice(index, 1, newItem)
}

export const isFilterApplied = formfilters =>
  !isEmpty(Object.values(formfilters).reduce((a, b) => a + b))

export const resetFilter = filter => {
  Object.entries(filter).forEach(([key, _]) => {
    filter[key] = ''
  })
}
//filtersConfig: {filter1: {key: String, text: String}, filter2...}
export const filterList = (list, filters, filtersConfig) =>
  list.filter(elem =>
    Object.keys(filtersConfig)
      .map(filterNr =>
        filterElem(
          elem[filtersConfig[filterNr].key],
          filters[filtersConfig[filterNr].key]
        )
      )
      .every(Boolean)
  )

const filterElem = (elem, filter) =>
  ('' + elem).toLowerCase().includes(filter.toLowerCase())

export const formatTextForMobileTable = (text, vuetify) => {
  return vuetify.breakpoint.xs && (isNone(text) || isEmpty(text)) ? '-' : text
}
