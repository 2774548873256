import safetyInspections from './safetyInspections'

const incidentRegistryRoutes = [
  {
    path: '/incidentregistry/actionplan',
    alias: [
      '/incidentregistry/actionplan/new',
      '/incidentregistry/actionplan/filter'
    ],
    name: 'ActionPlan',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/components/ActionPlanHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/ActionPlanList.vue`
        )
    },
    meta: {
      title: 'menu__item__action_plan',
      contentful: {
        pageHelp: '5uSrojz69RAuOtaIuFpYTA'
      },
      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Handlingsplan',
          string: 'menu__item__action_plan',
          disabled: true,
          href: '/incidentregistry/actionplan'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/incidentregistry/actionplan/:id',
    alias: '/incidentRegistry/actionplan/:id',
    name: 'ActionPlanEdit',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/components/ActionPlanEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/ActionPlan.vue`
        )
    },
    meta: {
      tracking: {
        name: 'Actionplan'
      },
      title: 'menu__item__action_plan',
      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Handlingsplan',
          string: 'menu__item__action_plan',
          disabled: false,
          href: '/incidentregistry/actionplan'
        },
        {
          text: 'Rediger tiltak',
          string: 'measures__edit_measure',
          disabled: true
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: '/incidentregistry/incidents',
    alias: [
      '/incidentRegistry/Incidents',
      '/incidentregistry/Incidents/new',
      '/incidentregistry/Incidents/filter'
    ],
    name: 'Incidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/components/IncidentsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/IncidentsList.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__incidents',
      contentful: {
        pageHelp: '2is8RtQbCPkvcdI3qBh3OU'
      },
      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Hendelser',
          string: 'menu__item__incidents',
          disabled: true,
          href: '/incidentregistry/incidents'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/incidentregistry/incidents/:id',
    alias: '/incidentRegistry/Incidents/:id',
    name: 'EditIncidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/IncidentsEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/Incidents.vue`
        )
    },
    meta: {
      title: 'menu__item__incidents',

      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Hendelser',
          string: 'menu__item__incidents',
          disabled: false,
          href: '/incidentregistry/incidents'
        },
        {
          text: 'Rediger hendelse',
          string: 'incidents__edit_incident',
          disabled: true
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: '/incidentregistry/inspections',
    name: 'Inspections',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Inspection" */ `@/views/incidentRegistry/inspectionReport/components/InspectionListHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Inspection" */ `@/views/incidentRegistry/inspectionReport/InspectionList.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__inspection_report',

      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Tilsyn',
          string: 'menu__item__inspection_report',
          disabled: true,
          href: '/incidentregistry/inspectionlist'
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: '/incidentregistry/inspections/:id',
    name: 'InspectionWithId',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/components/InspectionHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/Inspection.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__inspection_report',

      breadcrumbs: [
        {
          text: 'Hendelsesregister',
          string: 'menu__item__title__incident_registry',
          disabled: false,
          href: '/startside/dashboards/dashboard_eventregistry.xhtml'
        },
        {
          text: 'Tilsyn',
          string: 'menu__item__inspection_report',
          disabled: false,
          href: '/incidentregistry/inspections'
        },
        {
          text: 'Rediger tilsyn',
          string: 'inspectionreport__edit',
          disabled: true,
          href: '/incidentregistry/inspections/:id'
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Inspection',
        component: () =>
          import(
            /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/Inspection.vue`
          ),
        meta: {
          title: 'menu__item__inspection_report',
          contentful: {
            category: 'PTRYWviqqOePp2mXVa86B',
            pageHelp: 'R7oV0MFgjt6o7oew65ua9'
          }
        }
      }
    ],
    productionAvailable: true
  },
  safetyInspections
]

export default incidentRegistryRoutes
