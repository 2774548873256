import { useSafetyInspectionService } from '@/services'
import { PARENT_BREADCRUMBS } from './constans'

const detailsGuard = async (to, from, next) => {
  const { state } = useSafetyInspectionService()

  if (state.accessRights.write) next()
  else
    next({ name: 'SafetyInspectionsOverviewId', params: { id: to.params.id } })
}

const routes = {
  path: ':id/details',
  name: 'SafetyInspectionDetails',
  props: true,
  components: {
    hero: () =>
      import(
        `@/views/incidentRegistry/safetyInspections/hero/SafetyInspectionDetailsHero.vue`
      ),
    default: () =>
      import(
        `@/views/incidentRegistry/safetyInspections/views/SafetyInspectionDetails.vue`
      )
  },
  redirect: { name: 'SafetyInspectionsAbout' },
  beforeEnter(to, from, next) {
    if (to.params.id === '0' && to.name !== 'SafetyInspectionsAbout')
      next({ name: 'SafetyInspectionsAbout', params: { id: '0' } })

    next()
  },
  children: [
    {
      path: 'about',
      name: 'SafetyInspectionsAbout',
      meta: {
        title: 'safetyInspections__step_about_safety_inspection',
        nextStep: {
          routeName: 'SafetyInspectionsParticipants',
          name: 'safetyInspections__step_participants'
        },
        breadcrumbs: [...PARENT_BREADCRUMBS]
      },
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/safetyInspectionDetails/steps/SafetyInspectionsStepAbout.vue`
          )
      },
      beforeEnter: detailsGuard
    },
    {
      path: 'participants',
      name: 'SafetyInspectionsParticipants',
      meta: {
        title: 'safetyinspections__participants',
        nextStep: {
          routeName: 'SafetyInspectionsCheckpoints',
          name: 'safetyInspections__step_checkpoints'
        },
        previousStep: {
          routeName: 'SafetyInspectionsAbout',
          name: 'safetyInspections__step_about_safety_inspection'
        },
        breadcrumbs: [...PARENT_BREADCRUMBS]
      },
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/safetyInspectionDetails/steps/SafetyInspectionsStepParticipants.vue`
          )
      },
      beforeEnter: detailsGuard
    },
    {
      path: 'checkpoints',
      name: 'SafetyInspectionsCheckpoints',
      meta: {
        title: 'safetyInspections__plan_checkpoints',
        nextStep: {
          routeName: 'SafetyInspectionsPerform',
          name: 'safetyInspection__step_perform_inspection'
        },
        previousStep: {
          routeName: 'SafetyInspectionsParticipants',
          name: 'safetyInspections__step_participants'
        },
        breadcrumbs: [...PARENT_BREADCRUMBS]
      },
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/safetyInspectionDetails/steps/SafetyInspectionsStepPlan.vue`
          )
      },
      beforeEnter: detailsGuard
    },
    {
      path: 'perform',
      name: 'SafetyInspectionsPerform',
      meta: {
        title: 'safetyInspection__step_perform_inspection',
        nextStep: {
          routeName: 'SafetyInspectionsSummary',
          name: 'safetyInspections__step_summary'
        },
        previousStep: {
          routeName: 'SafetyInspectionsCheckpoints',
          name: 'safetyInspections__step_checkpoints'
        },
        breadcrumbs: [...PARENT_BREADCRUMBS]
      },
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/safetyInspectionDetails/steps/SafetyInspectionsStepPermformInspection.vue`
          )
      },
      beforeEnter: detailsGuard
    },
    {
      path: 'summary',
      name: 'SafetyInspectionsSummary',
      nextStep: {
        routeName: 'SafetyInspectionsOverviewCompleted',
        name: 'SafetyInspectionsSummary'
      },
      previousStep: { routeName: 'SafetyInspectionsPerform', name: 'perform' },
      meta: {
        title: 'safetyInspections__step_summary',
        breadcrumbs: [...PARENT_BREADCRUMBS]
      },
      components: {
        default: () =>
          import(
            `@/views/incidentRegistry/safetyInspections/safetyInspectionDetails/steps/SafetyInspectionsStepSummary.vue`
          )
      },
      beforeEnter: detailsGuard
    }
  ]
}

export default routes
