import { computed, reactive, toRefs } from 'vue'
import { getFileExtension, getMediaType } from '@/composable/common/fileType'
import { httpMethods, requestHandlerWithState } from './requestHandler'
import { removeItemAtIndex } from '@/utilities/arrayUtilities'
import { requestFailed } from '@/utilities/httpUtilities'
import { isSomething } from '../utilities/conditionalUtilities'
import { addOriginTitleAndRoute } from '../views/card-file/Files/components/originTitleList'
import { lazyDecode } from '@/utilities/stringUtilities'
import { useStore } from '@/store'
import { FLAG } from '@/constants/featureFlags.js'
import { escapeUnicode } from '@/utilities/fileUtilities.js'

const state = reactive({
  allFiles: [],
  filesFromFiles: computed(() =>
    [...state.allFiles].filter(file => file.ownerType === 'NONE')
  ),
  filesFromElsewhere: computed(() =>
    [...state.allFiles]
      .filter(file => file.ownerType !== 'NONE')
      .map(file => addOriginTitleAndRoute(file))
  ),
  file: {},
  previewable: false,
  previewURL: '',
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  fileCategories: [],
  filesForType: []
})

export const useFilesService = () => {
  //TODO REMOVE ONCE FILE SERVICE IN MONOLITH IS KILLED
  const getNewUrl = oldUrl => {
    const store = useStore()
    const monolithPath = 'monolith/api'
    const newPath = 'file/api'

    const useNewUrl = store.getters['featureFlags/isFeatureEnabled'](
      FLAG.USE_NEW_FILE_SERVICE
    )

    return useNewUrl
      ? oldUrl.replace(new RegExp(monolithPath, 'g'), newPath)
      : oldUrl
  }

  const getAllFilesForUser = async () => {
    const url = getNewUrl(`/monolith/api/file/allfilesforuser`)
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.hasContent) state.allFiles = result.data
  }

  const downloadFile = async (id, original_filename) => {
    const mimeType = getMediaType(original_filename)
    const store = useStore()
    const useDownloadFileHack = store.getters['featureFlags/isFeatureEnabled'](
      FLAG.USE_DOWNLOAD_FILE_HACK
    )
    const url = getNewUrl(`/monolith/api/file/${id}`)
    const headers = { responseType: 'blob' }
    const result = await requestHandlerWithState(
      state,
      httpMethods.GET,
      url,
      null,
      headers
    )
    const fileBlob = new Blob([result.data], { type: mimeType })
    if (result.success) {
      const downloadUrl = window.URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = downloadUrl
      link.download = original_filename
      document.body.appendChild(link)
      link.click()
      if (useDownloadFileHack) {
        setTimeout(() => {
          window.URL.revokeObjectURL(downloadUrl)
          document.body.removeChild(link)
        }, 10000)
      } else {
        window.URL.revokeObjectURL(downloadUrl)
        document.body.removeChild(link)
      }
    } else {
      state.request = requestFailed()
    }
  }

  const getFileInfo = async id => {
    const url = getNewUrl(`/monolith/api/file/fileinfo/${id}`)
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) state.file = result.data

    checkIfPreviewable(state.file.original_filename)
  }

  const deleteFile = async id => {
    const url = getNewUrl(`/monolith/api/file/${id}`)
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)
    if (result.success) {
      removeItemAtIndex(state.allFiles, id)
      if (id === state.file.id) {
        state.file = {}
      }
    }
    return result
  }

  const getFileCategories = async () => {
    const url = getNewUrl(`/monolith/api/file/categories`)
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.fileCategories = result.data.map(category => lazyDecode(category))
    return state.fileCategories
  }

  const addFileCategory = category => {
    if (isSomething(category)) {
      state.fileCategories.push(category)
    } else {
      return false
    }
  }

  const previewFile = async id => {
    const url = getNewUrl(`/monolith/api/file/${id}`)
    const headers = { responseType: 'blob' }
    const result = await requestHandlerWithState(
      state,
      httpMethods.GET,
      url,
      null,
      headers
    )
    if (result.success) {
      state.previewURL = window.URL.createObjectURL(new Blob([result.data]))
      return state.previewURL
    } else {
      state.request = requestFailed()
    }
  }

  const checkIfPreviewable = filename => {
    if (
      getMediaType(filename)?.split('/')[0] === 'image' &&
      getFileExtension(filename) !== 'svg'
    ) {
      state.previewable = true
    } else {
      state.previewable = false
    }
  }

  const clearFileState = () => {
    state.file = {}
  }

  const clearPreviewUrl = () => {
    state.previewURL = ''
  }

  const updateFile = async (fileId, changedFileData) => {
    const formData = new FormData()
    formData.append('id', fileId)
    formData.append('title', escapeUnicode(changedFileData.title))
    formData.append('category', escapeUnicode(changedFileData.category || ''))
    formData.append(
      'description',
      escapeUnicode(changedFileData.description || '')
    )
    const headers = { 'Content-Type': 'multipart/form-data' }
    const url = getNewUrl(`/monolith/api/file/update`)
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      formData,
      headers
    )
    await getFileInfo(fileId)
    if (result.success) {
      const file = result.data

      const index = state.allFiles.findIndex(e => e.id === fileId)
      state.allFiles.splice(index, 1, file)
    }
    return result
  }

  const updateCategoryTitle = async (oldCategoryTitle, newCategoryTitle) => {
    const url = getNewUrl(`/monolith/api/file/updateCategoryTitle`)
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      oldCategoryTitle,
      newCategoryTitle
    })

    return result
  }

  const getFileListForType = async (ownerType, ownerId, loadChildfiles) => {
    const url = getNewUrl(`/monolith/api/file/filelisttypetypeid`)
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      ownerType: ownerType,
      ownerId: ownerId,
      loadChildfiles: loadChildfiles
    })
    if (result.data && result.success) {
      result.data.files =
        result.data?.files?.length > 0 ? result.data.files : []
      state.filesForType = result.data
    }
    return result
  }

  const getAccountLogo = async () => {
    const url = getNewUrl(`/monolith/api/file/accountlogo`)
    const result = await requestHandlerWithState(
      state,
      httpMethods.GET,
      url,
      null,
      { responseType: 'blob' }
    )
    return result.success && result.hasContent ? result.data : null
  }

  const getFileCountsByOwnerIds = async (ownerIds, ownerType) => {
    const url = getNewUrl(`/monolith/api/file/fileListCount`)
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      ownerType,
      ownerIds
    })
    return result && result.data ? result.data : null
  }

  return {
    state: toRefs(state),
    getAllFilesForUser,
    downloadFile,
    getFileCategories,
    getFileInfo,
    deleteFile,
    previewFile,
    clearFileState,
    clearPreviewUrl,
    addFileCategory,
    updateFile,
    updateCategoryTitle,
    getFileListForType,
    getAccountLogo,
    getFileCountsByOwnerIds,
    getNewUrl,
    checkIfPreviewable
  }
}
