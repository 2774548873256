<template>
  <div class="d-flex flex-column mt-6 input-wrapper">
    <label :for="`dateTimePicker-${id}-wrapper`">{{ label }}</label>
    <div class="datepicker" :id="`dateTimePicker-${id}-wrapper`">
      <!-- Text Field -->
      <label class="calendar-format-hint darkergray--text" :for="`dateTimePicker-${id}-textfield`" v-show="textInput === ''">
        {{ $t('timerecorder__format') }}
      </label>
      <input
        type="text"
        class="textfield"
        :id="`dateTimePicker-${id}-textfield`"
        v-model="textInput"
        data-input
        @click="open"
        @keyup.enter="blur"
        @submit="blur"
      />
      <v-icon class="calendar-icon">mdi-calendar-blank mdi-24px</v-icon>
      <!-- Picker -->
      <div class="picker-wrapper" v-show="visible">
        <v-date-picker :id="`dateTimePicker-${id}-picker`" color="secondary" first-day-of-week="1" class="picker" v-model="date" no-title full-width>
          <!-- Time Selector -->
          <template v-slot>
            <div class="d-flex flex-column mt-n4 px-4 pb-2">
              <!-- Time Preview -->
              <div class="d-flex flex-row align-center">
                <label class="col-5">{{ $t('timerecorder__time') }}</label>
                <div class="d-flex flex-column ml-1 pr-12 col-7">
                  <span>{{ time }}</span>
                  <v-divider />
                </div>
              </div>
              <!-- Select Hour -->
              <div class="d-flex flex-row align-center mt-1">
                <label class="col-5">{{ $t('timerecorder__hours') }}</label>
                <v-select
                  v-model="hour"
                  :items="hourOptions"
                  class="py-0 my-0 ml-4 mr-12"
                  :id="`dateTimePicker-${id}-hourSelector`"
                  @update="setHour"
                  dense
                  hide-details
                  outlined
                  style="z-index: 99999999;"
                />
              </div>
              <!-- Select Minute -->
              <div class="d-flex flex-row align-center mt-1">
                <label class="col-5">{{ $t('timerecorder__minutes') }}</label>
                <v-select
                  v-model="minute"
                  :items="minuteOptions"
                  class="py-0 my-0 ml-4 mr-12"
                  :id="`dateTimePicker-${id}-minuteSelector`"
                  @update="setMinute"
                  dense
                  hide-details
                  outlined
                  style="z-index: 99999999;"
                />
              </div>
              <!-- Bottom Buttons -->
              <div class="d-flex flex-row mt-4">
                <v-btn @click="now" depressed :id="`dateTimePicker-${id}-nowButton`">{{ $t('timerecorder__now') }}</v-btn>
                <v-spacer />
                <v-btn @click="close" depressed :id="`dateTimePicker-${id}-closeButton`">{{ $t('common__close') }}</v-btn>
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
import { convertDateAndTimeFormats } from '@/composable/menu/timeRecorderFormats'
export default {
  name: 'LegacyDateTimePicker',
  props: ['id', 'label', 'isoString'],
  setup() {
    const { isoStringToLocale, localeToISOString } = convertDateAndTimeFormats()
    const generateArray = length => {
      const array = []
      for (let i = 0; i < length; i++) array.push(i < 10 ? `0${i}` : `${i}`)
      return array
    }
    const hourOptions = generateArray(24)
    const minuteOptions = generateArray(60)
    return {
      isoStringToLocale,
      localeToISOString,
      hourOptions,
      minuteOptions
    }
  },
  data() {
    return {
      visible: false,
      date: null,
      hour: '00',
      minute: '00'
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    setDateTime(isoString) {
      const [dateString, timeString] = isoString.split('T')
      this.date = dateString
      this.hour = timeString.split(':')[0]
      this.minute = timeString.split(':')[1]
    },
    now() {
      const doubleDigits = d => (d < 10 ? `0${d}` : `${d}`)
      const date = new Date()
      const year = date.getFullYear().toString()
      const month = doubleDigits(date.getMonth() + 1)
      const day = doubleDigits(date.getDate())
      const hour = doubleDigits(date.getHours())
      const minute = doubleDigits(date.getMinutes())
      this.setDateTime(`${year}-${month}-${day}T${hour}:${minute}:00`)
    },
    blur() {
      this.close()
      const textfield = document.getElementById(`dateTimePicker-${this.id}-textfield`)
      textfield.blur()
    },
    reset() {
      this.date = null
      this.hour = '00'
      this.minute = '00'
    },
    setHour(hour) {
      if (!this.date) {
        this.now()
        this.hour = hour
      }
    },
    setMinute(minute) {
      if (!this.date) {
        this.now()
        this.minute = minute
      }
    }
  },
  computed: {
    textInput: {
      get: function() {
        return this.isoStringToLocale(this.isoString)
      },
      set: function(localeString) {
        const isoString = this.localeToISOString(localeString)
        if (isoString) this.setDateTime(isoString)
      }
    },
    time() {
      return `${this.hour}:${this.minute}`
    },
    toISOString() {
      if (!this.date || !this.hour || !this.minute) return null
      return `${this.date}T${this.hour}:${this.minute}:00`
    }
  },
  watch: {
    isoString(newValue) {
      if (!newValue) this.reset()
      else if (newValue != this.toISOString) {
        this.setDateTime(newValue)
      }
    },
    toISOString(newValue) {
      this.textInput = this.isoStringToLocale(newValue)
      if (newValue != this.isoString) {
        this.$emit('updated', newValue)
      }
    }
  },
  mounted() {
    // Close picker on click-outside
    window.addEventListener('click', e => {
      if ([...document.querySelectorAll('.v-menu__content')].find(m => m.contains(e.target))) return
      else if (this.visible && !document.getElementById(`dateTimePicker-${this.id}-wrapper`)?.contains(e.target)) this.close()
    })
  }
}
</script>

<style lang="scss">
.picker-wrapper {
  position: relative;
  .picker {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 8px 16px rgba(0, 0, 0, 0.25);
  }
}

.datepicker {
  position: relative;
  .calendar-format-hint {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
  }
  .textfield {
    height: 48px;
    width: 100%;
    border-radius: 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #b4e0d1;
    transition: border-color 0.2s;
    font-size: 16px;
    background-color: white;
    padding-left: 10px;
    &:focus {
      border-color: #028655;
    }
  }
  .calendar-icon {
    pointer-events: none;
    position: absolute;
    top: 14px;
    right: 10px;
    font-size: 20px;
  }
}
</style>
