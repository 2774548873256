import { reactive, toRefs } from 'vue'
import { addNewItem } from '@/utilities/table'
import { httpMethods, requestHandlerWithState } from './requestHandler'
import { replaceNewItem } from '../utilities/table.js'
import { removeItemAtIndex, replaceItem } from '@/utilities/arrayUtilities'
import { hasItems } from '@/utilities/conditionalUtilities'

const state = reactive({
  vacationOverview: [],
  vacationYears: [],
  errorArray: [],
  calendarOverviewData: [],
  calendarOverviewDataWeekly: {},
  vacationTransferList: [],
  availebleDates: [],
  employeesByLocationAcccess: []
})

export const useEmployerVacationService = () => {
  const getVacationOverview = async year => {
    const url = `hr/api/vacation/vacationOverview/${year}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.vacationOverview = result.data
      if (hasItems(state.vacationOverview)) {
        state.vacationOverview?.forEach(item => {
          item.vacations = item.vacations.sort(
            (a, b) =>
              a.approved - b.approved || new Date(a.from) - new Date(b.from)
          )
        })
      }
    }
  }

  const findAvailableDates = async (employeeid, from, to) => {
    const url = `hr/api/vacation/findAvailableDates/${employeeid}/${from}/${to}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url, {
      employeeid,
      from,
      to
    })
    if (result.success) state.availebleDates = result.data
  }

  const getActiveEmployeeNamesByLocationAccess = async (location, access) => {
    const url = `hr/api/employee/getActiveEmployeesByLocationAccess/${location}/${access}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url, {
      location,
      access
    })
    if (result.success) state.employeesByLocationAcccess = result.data
  }

  const findVacationYears = async () => {
    const url = `/hr/api/vacation/findVacationYears`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.vacationYears = result.data
  }

  //Save vacation for multiple employees
  const addVactionDaysToEmployees = async (
    employeeIds,
    year,
    days,
    discardPrevious
  ) => {
    const url = `hr/api/vacation/addVactionDaysToEmployees`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      employeeIds,
      year,
      days,
      discardPrevious
    })
    if (result.success) {
      const { updated_employee_ids: employeeIds } = result.data
      employeeIds.forEach(id => {
        const index = state.vacationTransferList.findIndex(
          e => e.employeeId === id
        )
        const updatedItem = { ...state.vacationTransferList[index] }
        updatedItem.days = days

        replaceNewItem(updatedItem, state.vacationTransferList, index)
      })
    }
    return result
  }

  const transferVacationDays = async (
    toYear,
    maxTransferDays,
    overwrite = true
  ) => {
    const url = `hr/api/vacation/transferVactionDays/${toYear}/${maxTransferDays}/${overwrite}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url)

    const currentYearInTable = state.vacationTransferList[0].year

    if (result.success && toYear === currentYearInTable) {
      Object.keys(result.data).forEach(id => {
        const employeeId = parseInt(id)
        const days = result.data[id]

        const vacationTransferIndex = state.vacationTransferList.findIndex(
          e => {
            return e.employeeId === employeeId
          }
        )
        const newItem = {
          ...state.vacationTransferList[vacationTransferIndex],
          fromLastYear: days
        }
        replaceNewItem(
          newItem,
          state.vacationTransferList,
          vacationTransferIndex
        )
      })
    }
    return result
  }

  const updateEmployeeVacationDays = async (
    employeeId,
    year,
    days,
    fromLastYear
  ) => {
    const url = `/hr/api/vacation/updateEmployeeVacationDays`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      employeeId,
      year,
      days,
      fromLastYear
    })

    if (result.success)
      replaceItem(
        state.vacationTransferList,
        item => item.employeeId === result.data.employeeId,
        { ...result.data, newItem: true }
      )

    return result
  }

  const saveVacationEmployeeList = async item => {
    const url = `/hr/api/vacation/saveVacationList`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      item
    )

    if (result.success) {
      const successArray = result.data.filter(e => e.type === 'SUCCESS')
      const errorArray = result.data.filter(e => e.type === 'ERROR')
      state.errorArray = errorArray

      successArray.forEach(item => {
        const employeeIndex = state.vacationOverview.findIndex(i => {
          return item.vacationAdminOverviewDto.employee_id === i.employee.id
        })
        addNewItem(
          item.vacationAdminOverviewDto,
          state.vacationOverview[employeeIndex].vacations
        )
      })

      return successArray
    }
  }

  const replaceVacation = async (resultId, vacationId, employeeId) => {
    const vacation = await findVacationById(resultId)
    const employeeIndex = state.vacationOverview.findIndex(e => {
      return e.employee.id == employeeId
    })
    vacation.approved = true

    const vacationIndex = state?.vacationOverview[
      employeeIndex
    ]?.vacations.findIndex(e => {
      return e.id == vacationId
    })

    if (vacationIndex !== -1) {
      state.vacationOverview[employeeIndex].vacations.splice(vacationIndex, 1)
      addNewItem(
        vacation,
        state.vacationOverview[employeeIndex].vacations,
        vacationIndex
      )
    } else {
      addNewItem(vacation, state.vacationOverview[employeeIndex].vacations)
    }
  }

  //Save vacation for one employer
  const saveVacationEmployer = async item => {
    const url = `/hr/api/vacation/saveVacation`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      item
    )

    if (result.success) {
      const vacation = result.data.vacationAdminOverviewDto

      const employeeIndex = state.vacationOverview.findIndex(e => {
        return e.employee.id == item.employee_id
      })
      vacation.approved = true

      const vacationIndex = state.vacationOverview[
        employeeIndex
      ].vacations.findIndex(e => {
        return e.id == item.id
      })

      if (vacationIndex !== -1) {
        state.vacationOverview[employeeIndex].vacations.splice(vacationIndex, 1)
        addNewItem(
          vacation,
          state.vacationOverview[employeeIndex].vacations,
          vacationIndex
        )
      } else {
        addNewItem(vacation, state.vacationOverview[employeeIndex].vacations)
      }
    }
    return result
  }

  const findVacationById = async id => {
    const url = `/hr/api/vacation/findvacationbyid/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    return result.data
  }

  const approveVacationRequest = async (id, employeeId) => {
    const url = `/hr/api/vacation/approveVacationRequest/${id}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url)
    if (result.success) {
      replaceVacation(result.data.object_id, id, employeeId)
    }
    return result
  }

  const declineVacationRequest = async (id, message, employeeId) => {
    const url = `/hr/api/vacation/removeVacationRequest/${id}/${message}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      id,
      message
    })
    const employeeIndex = state.vacationOverview.findIndex(e => {
      return e.employee.id == employeeId
    })
    if (result.success)
      removeItemAtIndex(state.vacationOverview[employeeIndex].vacations, id)
    return result
  }

  const removeVacationById = async (id, employeeId) => {
    const url = `/hr/api/vacation/removeVacation/${id}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url)

    const employeeIndex = state.vacationOverview.findIndex(e => {
      return e.employee.id == employeeId
    })
    if (result.success)
      removeItemAtIndex(state.vacationOverview[employeeIndex].vacations, id)
    return result
  }
  const getOverviewDataForCalendar = async (
    from,
    to,
    elementTypes,
    accessContext = 'VACATION',
    showAllEmployees = true
  ) => {
    const url = '/hr/api/planningoverview/getOverviewData/v2'
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      from,
      to,
      elementTypes,
      accessContext,
      showAllEmployees
    })
    if (result.success) return result.data
  }

  const getEmployeesVacationByYear = async year => {
    const url = `/hr/api/vacation/employeesVactionDaysByYear/${year}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success && result.hasContent)
      state.vacationTransferList = result.data
    return result
  }

  const addVacationDays = async (year, days, discardPrevious = true) => {
    const url = `/hr/api/vacation/addvactiondays/${year}/${days}/${discardPrevious}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url)

    if (result.success) return result
  }

  return {
    state: toRefs(state),
    getVacationOverview,
    findVacationYears,
    saveVacationEmployeeList,
    saveVacationEmployer,
    declineVacationRequest,
    removeVacationById,
    getOverviewDataForCalendar,
    findVacationById,
    approveVacationRequest,
    replaceVacation,
    getEmployeesVacationByYear,
    addVacationDays,
    findAvailableDates,
    addVactionDaysToEmployees,
    transferVacationDays,
    updateEmployeeVacationDays,
    getActiveEmployeeNamesByLocationAccess
  }
}
