import { reactive, toRefs } from 'vue'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'
import { addNewItem } from '@/utilities/table'
import { toObject, removeItemAtIndex } from '../utilities/arrayUtilities'

const state = reactive({
  inspections: [],
  inspection: null,
  inspectionTypes: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  writeAccess: false,
  readAccess: false
})

export const useInspectionService = () => {
  const loadInspections = async () => {
    const url = '/hse/api/inspectionreport'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.inspections = result.data
  }

  const loadInspection = async id => {
    const url = `/hse/api/inspectionreport/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.inspection = result.data
  }

  const saveInspectionReport = async inspectionDto => {
    const url = '/hse/api/inspectionreport/saveInspectionReport'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      inspectionDto
    )

    if (result.data.success) {
      state.inspection = result.data
      addNewItem({ ...result.data }, state.inspections)
    }

    return result.data
  }

  const deleteInspectionReport = async id => {
    let dto = {
      'ids': []
    }
    dto.ids.push(id)
    const url = `/hse/api/inspectionreport/deleteinspectionreports`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      dto
    )

    if (result.success) removeItemAtIndex(state.inspections, id)

    return result
  }

  return {
    state: toRefs(state),
    loadInspections,
    loadInspection,
    saveInspectionReport,
    deleteInspectionReport
  }
}
