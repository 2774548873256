import { FLAG } from '@/constants/featureFlags.js'
import alias from './alias'

const iFrameRoutes = [
  {
    path: '/monolith',
    name: 'iFrameMonolith',
    alias: alias,
    components: {
      default: () => import(`@/views/monolith`)
    },
    meta: {
      requiresAccessRights: [
        FLAG.USE_IFRAME_MONOLITH,
      ],
    }
  }
]

export default iFrameRoutes

