<template>
  <AvonovaModal
    dialog
    :title="eitherOr(modalObject.title, currentMode.title)"
    width="500"
    :value="modalObject.showDialog"
    @modalButtonClose="cancel()"
  >
    <template v-slot:content>
      <p style="text-align: center">
        {{ eitherOr(modalObject.text, currentMode.text) }}
      </p>
    </template>
    <template v-slot:footer>
      <AvonovaButton
        id="confirmation-dialog-cancel-button"
        @click="cancel()"
        :color="eitherOr(currentMode.cancel.color, '')"
        :icon="eitherOr(currentMode.cancel.icon, '')"
        iconPosition="left"
        :mobileButton="$vuetify.breakpoint.xs"
        >{{ eitherOr(modalObject.cancelText, currentMode.cancel.text) }}
      </AvonovaButton>

      <AvonovaButton
        class="ml-0 ml-sm-6"
        id="confirmation-dialog-confirm-button"
        :color="currentMode.confirm.color"
        @click="modalObject.confirm(), closeModal()"
        iconPosition="left"
        :icon="eitherOr(currentMode.confirm.icon, '')"
        :mobileButton="$vuetify.breakpoint.xs"
      >
        {{ eitherOr(modalObject.confirmText, currentMode.confirm.text) }}
      </AvonovaButton>
    </template>
  </AvonovaModal>
</template>

<script>
import { AvonovaModal, AvonovaButton } from '@hms-kontoret/avonova-storybook'
import { computed } from 'vue'
import { eitherOr } from '@/utilities/conditionalUtilities'
import i18n from '@/config/i18n'
export default {
  name: 'ConfirmationDialog',
  components: {
    AvonovaModal,
    AvonovaButton
  },
  props: {
    modalObject: { type: Object }
  },
  setup(props, { emit }) {
    const closeModal = () => emit('closeModal')
    const mode = computed(() => props.modalObject.mode ?? 'unsavedChanges')
    const cancel = () => {
      if (props.modalObject.cancel) props.modalObject.cancel()
      closeModal()
    }
    const modeContentfulKeys = {
      delete: {
        title: i18n.t('common__delete_text'),
        text: i18n.t('common__delete_explanation'),
        confirm: {
          text: props.modalObject.buttonText || i18n.t('common__delete'),
          color: 'red300',
          icon: 'n-icon-trash-can'
        },
        cancel: { text: i18n.t('common__cancel'), color: 'darkStroke' }
      },
      unsavedChanges: {
        title: i18n.t('common__unsaved_changes_modal'),
        text: i18n.t('common__unsaved_changes_reminder_content'),
        confirm: {
          text: i18n.t('common__discard_changes'),
          color: 'red300',
          icon: 'n-icon-trash-can'
        },
        cancel: {
          text: i18n.t('common__back'),
          color: 'darkStroke',
          icon: 'n-icon-e-remove'
        }
      },
      confirm: {
        title: '',
        text: '',
        confirm: {
          text: i18n.t('common__save'),
          icon: 'n-icon-check-single'
        },
        cancel: {
          text: i18n.t('common__cancel'),
          color: 'darkStroke'
        }
      }
    }
    const currentMode = computed(() => modeContentfulKeys[mode.value])
    return { closeModal, mode, eitherOr, currentMode, cancel }
  }
}
</script>

<style lang="scss" scoped></style>
