<template>
  <div class="topMenuContainer pa-0" v-if="menuRights">
    <div class="topMenuContainer-inner">
      <div class="d-flex main-menu-left">
        <div class="main-menu-left-inner">
          <div>
            <IconMenu :data="iconItems"></IconMenu>
          </div>
        </div>
      </div>
      <div class="d-flex main-menu-right">
        <div class="d-flex flex-wrap main-menu-right-inner">
          <template v-if="isUserHandbookOnly">
            <MenuCat
              v-if="hasHandookOnlyUsersData.length"
              :data="hasHandookOnlyUsersData"
              title="menu__item__title__my_pages"
              id="handbookOnlyContainer"
            ></MenuCat>
          </template>
          <template v-else>
            <MenuCat
              v-if="unitInformationItems.length"
              :data="unitInformationItems"
              title="menu__item__title__unit_information"
              id="enhetsinformasjoncontainer"
            ></MenuCat>
            <MenuCat
              v-if="personnelItems.length"
              :data="personnelItems"
              title="menu__item__title__personnel"
              id="personellcontainer"
            ></MenuCat>
            <MenuCat
              v-if="directivesItems.length"
              :data="directivesItems"
              title="menu__item__title__directives"
              id="mgmtdocscontainer"
            ></MenuCat>
            <MenuCat
              v-if="riskManagementItems.length"
              :data="riskManagementItems"
              title="menu__item__title__risk_management"
              id="riskcontainer"
            ></MenuCat>
            <MenuCat
              v-if="incidentRegistryItems.length"
              :data="incidentRegistryItems"
              title="menu__item__title__incident_registry"
              id="eventcontainer"
            ></MenuCat>
            <MenuCat
              v-if="cardFileItems.length"
              :data="cardFileItems"
              title="menu__item__title__card_file"
              id="cardcontainer"
            ></MenuCat>
            <MenuCat
              v-if="settingsItems.length"
              :data="settingsItems"
              title="menu__item__title__settings"
              id="settingscontainer"
            ></MenuCat>
            <MenuCat
              v-if="manualsItems.length"
              :data="manualsItems"
              title="menu__item__title__manuals"
              id="manualscontainer"
            ></MenuCat>
            <MenuCat
              v-if="accountManagementItems.length"
              :data="accountManagementItems"
              title="menu__item__title_account_administration"
              id="accountManagementContainer"
            ></MenuCat>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const html = document.getElementsByTagName('html')[0]
import MenuCat from './menu-parts/MenuCat'
import IconMenu from './menu-parts/IconMenu'
import accountManagement from '../shared/menu-data/accountManagement.js'
import cardFile from '../shared/menu-data/cardFile.js'
import directives from '../shared/menu-data/directives.js'
import incidentRegistry from '../shared/menu-data/incidentRegistry.js'
import manuals from '../shared/menu-data/manuals.js'
import personnel from '../shared/menu-data/personnel.js'
import riskManagement from '../shared/menu-data/riskManagement.js'
import settings from '../shared/menu-data/settings.js'
import unitInformation from '../shared/menu-data/unitInformation.js'
import myPages from '../shared/menu-data/myPages.js'
import handookOnlyUsersData from '@/shared/menu-data/handookOnlyUsersData'
import { ref } from 'vue'
export default {
  props: ['menuRights', 'toggleMainMenu'],
  name: 'MainMenuDropdown',
  components: {
    IconMenu,
    MenuCat
  },
  setup() {
    const mobileMenuExpanded = ref(false)

    return {
      accountManagement,
      cardFile,
      directives,
      incidentRegistry,
      manuals,
      personnel,
      riskManagement,
      settings,
      unitInformation,
      myPages,
      mobileMenuExpanded,
      handookOnlyUsersData
    }
  },
  data() {
    return {
      //
    }
  },
  computed: {
    isUserHandbookOnly() {
      return this.menuRights.includes('BRUKERE')
    },
    iconItems() {
      if (!this.getMenuData.includes('MYPAGESCONTAINER')) return [] // Remove check if (when?) CONTAINERS aren't bundled. If so, check length of natural array instead.
      return this.filterItemsByPermission('MYPAGESCONTAINER', this.myPages)
    },
    unitInformationItems() {
      if (!this.getMenuData.includes('ENHETSINFORMASJONCONTAINER')) return []
      return this.filterItemsByPermission(
        'ENHETSINFORMASJONCONTAINER',
        this.unitInformation
      )
    },
    personnelItems() {
      if (!this.getMenuData.includes('PERSONELLCONTAINER')) return []
      return this.filterItemsByPermission('PERSONELLCONTAINER', this.personnel)
    },
    cardFileItems() {
      if (!this.getMenuData.includes('CARDCONTAINER')) return []
      return this.filterItemsByPermission('CARDCONTAINER', this.cardFile)
    },
    directivesItems() {
      if (!this.getMenuData.includes('MGMTDOCSCONTAINER')) return []
      return this.filterItemsByPermission('MGMTDOCSCONTAINER', this.directives)
    },
    incidentRegistryItems() {
      if (!this.getMenuData.includes('EVENTCONTAINER')) return []
      return this.filterItemsByPermission(
        'EVENTCONTAINER',
        this.incidentRegistry
      )
    },
    manualsItems() {
      if (!this.getMenuData.includes('MANUALSCONTAINER')) return []
      return this.filterItemsByPermission('MANUALSCONTAINER', this.manuals)
    },
    riskManagementItems() {
      if (!this.getMenuData.includes('RISKCONTAINER')) return []
      return this.filterItemsByPermission('RISKCONTAINER', this.riskManagement)
    },
    settingsItems() {
      if (!this.getMenuData.includes('SETTINGSCONTAINER')) return []
      return this.filterItemsByPermission('SETTINGSCONTAINER', this.settings)
    },
    accountManagementItems() {
      if (!this.getMenuData.includes('ACCOUNTMANAGEMENTCONTAINER')) return []
      return this.filterItemsByPermission(
        'ACCOUNTMANAGEMENTCONTAINER',
        this.accountManagement
      )
    },
    hasHandookOnlyUsersData() {
      if (!this.getMenuData.includes('BRUKERE')) return []
      return this.filterItemsByPermission('BRUKERE', this.handookOnlyUsersData)
    },

    legacySmallOrMobile() {
      return window.matchMedia('(max-width: 768px)').matches
    },
    legacyBiggerThanMobile() {
      return window.matchMedia('(min-width: 768px)').matches
    },
    breakpoint() {
      return this.$vuetify.breakpoint
    },
    getMenuData() {
      return this.menuRights
    }
  },
  methods: {
    filterItemsByPermission(permission, items) {
      if (!this.menuRights.includes(permission)) return []
      return items.filter(item => this.menuRights.includes(item.permissionName))
    }
  },
  mounted() {
    html.classList.add('hide-scroll')
  },
  destroyed() {
    html.classList.remove('hide-scroll')
  }
}
</script>
<style lang="scss">
.topMenuContainer {
  overflow: auto;
  max-height: 90vh;
}
.topMenuContainer-inner {
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
  ul {
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
    li {
      a:focus:not(.router-link) {
        margin: 0 !important;
        padding: 2px 0px !important;
      }
    }
  }
}
.ui-menu {
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    width: 50%;
    padding: 0.5rem;
  }
  @media (min-width: 1025px) {
    width: 33.33333%;
  }
  @media (min-width: 1440px) {
    width: 25%;
    max-width: 25%;
  }
}
.main-menu-right {
  .main-menu-right-inner {
    width: 100%;
  }
  li {
    a {
      border-radius: 3px;
      padding: 2px 0 2px 0;
      display: block;

      color: rgb(51, 51, 51) !important;
      line-height: 24px !important;
      font-size: 16px !important;
      font-family: Inter, sans-serif !important;

      align-items: center;
      justify-content: center;
      text-decoration: none;
      &:hover {
        color: var(--v-primary-base);
      }
    }
  }

  flex-wrap: wrap;
  //col-12 col-md-8 pa-0 pa-md-5
  @media (min-width: 768px) {
    padding: 20px;
    flex: 1 1 auto;
  }
  /*@media (min-width: 1440px) {
    overflow: unset;
  }*/
}
.main-menu-left-inner {
  width: 100%;
}
.main-menu-left {
  background: #f8f8f6;

  .mainIconMenu {
    width: 100%;
  }

  .mainIconMenu li {
    /* display: flex;
    flex: 0 0 33.33333%;
    justify-content: center;
    align-items: center;*/
    flex: 0 0 33.33333%;
    min-height: 100px;
    @media (min-width: 768px) {
      flex: 0 0 50%;
      min-height: 220px;
    }
    @media (min-width: 1025px) {
      min-height: 150px;
    }
  }

  @media (min-width: 768px) {
    flex: 0 0 30%;
    padding: 20px;
    background-color: #f8f8f6;
  }

  .v-icon {
    font-size: 1.5em !important;
    margin-bottom: 0.25rem;
  }
  .menu-icon-text {
    font-size: 0.75em;
    font-family: Inter, sans-serif !important;
    color: #333333 !important;
  }
}
.main-menu-left .a-outer {
  width: 100%;
  height: 100%;

  a {
    color: #333333 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &:hover,
    &:hover i {
      background: var(--v-gray-base);
      color: var(--v-primary-base);
    }
  }
}
.topMenuContainer .menu-header-mobile {
  background: #b4e0d1;
  padding: 12px 12px 12px 15px;
  font-size: 18px;
  margin-bottom: 0px;

  i {
    font-size: 1.2em;
  }
}
.topMenuContainer .menu-header {
  font-size: 20px;
  margin-bottom: 0.2em;
}
.topMenuContainer .menu-item-mobile {
  border-bottom: #e0e0e0 1px solid;
  a {
    padding: 12px 12px 12px 26px;
  }
  &:last-child {
    border: 0;
  }
}
.topMenuContainer {
  overflow-y: auto;
  background: #fff;
  max-width: 1320px;
  margin: 85px auto 0 auto;
}

.v-application .mainTextMenu {
  padding: 0;
  margin: 0;
}
.mobile-hide {
  display: none;
}
.mobile-menu-item-active {
  margin-bottom: 25px !important;
}
.hide-scroll {
  overflow: hidden;
}
</style>
