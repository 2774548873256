import { reactive, toRefs } from 'vue'
import { httpMethods, requestHandlerWithState } from './requestHandler'

const state = reactive({
  selectableYears: [],
  employeePositions: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const usePlanningOverviewService = () => {
  const getSelectableYears = async () => {
    const url = `/hr/api/planningoverview/getSelectableYears`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) state.selectableYears = result.data
  }

  const getEmployeePositions = async () => {
    const url = `/hr/api/planningoverview/getEmployeePositions`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) state.employeePositions = result.data
  }

  return {
    state: toRefs(state),
    getSelectableYears,
    getEmployeePositions
  }
}
