import { reactive } from 'vue'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from '../requestHandler'
import { isNone } from '@/utilities/conditionalUtilities'
import { addNewItem } from '@/utilities/table'
import { replaceItem } from '@/utilities/arrayUtilities'

const state = reactive({
  supportAccessUsers: [],
  supportAccessCountries: [],
  supportAccessDepartments: [],
  supportUsers: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useSupportAccessService = () => {
  const getSupportAccessUsers = async () => {
    const url = '/common/api/supportAccessAdmin/getConnectedSupportAccesses'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.supportAccessUsers = result.data.map(supportAccess => {
        return {
          id: parseToEmptyStringIfNone(supportAccess.access_id),
          user_name: parseToEmptyStringIfNone(
            supportAccess.access_given_to.name
          ),
          department: parseToEmptyStringIfNone(
            supportAccess.access_given_to.supplier_department
          ),
          created: supportAccess.created_date,
          date_of_expiry: supportAccess.valid_thru_date,
          created_by_user: parseToEmptyStringIfNone(supportAccess.issuedBy),
          account_id: parseToEmptyStringIfNone(supportAccess.account_id),
          country: countryMapper(
            parseToEmptyStringIfNone(supportAccess.country)
          ),
          account_name: parseToEmptyStringIfNone(supportAccess.account_name)
        }
      })
    }
  }

  const parseToEmptyStringIfNone = text => {
    return isNone(text) ? '' : text + ''
  }

  const getSupportAccessDepartments = async () => {
    const url = '/common/api/supportAccessAdmin/getSupplierDepartments'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.supportAccessDepartments = result.data.map((department, index) => ({
        id: index,
        type: department,
        text: department
      }))
    }
  }

  const countryMapper = countryCode => {
    var countries = {
      no: 'Norway',
      sv: 'Sweden'
    }
    return countries[countryCode.toLowerCase()]
      ? countries[countryCode.toLowerCase()]
      : ''
  }

  const getSupportUsers = async () => {
    const url = '/common/api/supportAccessAdmin/supportUsers'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.supportUsers = result.data
  }

  const getSupportUsersType = async () => {
    const url = '/common/api/supportAccessAdmin/getAvailableSupportUsersTypes'
    const result = await requestHandler(httpMethods.GET, url)
    return result
  }

  const upsertSupportUsers = async supportUsers => {
    const url = '/common/api/supportAccessAdmin/updateSupportUsers'
    const result = await requestHandler(httpMethods.POST, url, supportUsers)

    const isNewSupportUser = isNone(supportUsers[0].support_user_id)
    if (result.success) {
      supportUsers[0].support_user_id = result.data[0].support_user_id

      if (isNewSupportUser) {
        // add new to top of the table with highlight
        addNewItem(supportUsers[0], state.supportUsers)
      } else {
        // replace existing data with current
        replaceItem(
          state.supportUsers,
          supportUser =>
            supportUser.support_user_id === result.data[0].support_user_id,
          supportUsers[0]
        )
      }
    }
    return result
  }

  const deleteSupportUsers = async deleteIds => {
    const url = '/common/api/supportAccessAdmin/removeSupportUsers'
    const result = await requestHandler(httpMethods.POST, url, deleteIds)

    if (result.success) {
      deleteIds.ids.forEach(id => {
        const index = state.supportUsers.findIndex(
          item => item.support_user_id === id
        )
        return index > -1 ? state.supportUsers.splice(index, 1) : ''
      })
    }

    return result
  }

  const deleteSupportAccess = async deleteIds => {
    const url = '/common/api/supportAccessAdmin/removeSupportAccesses'
    const result = await requestHandler(httpMethods.POST, url, deleteIds)

    deleteIds.ids.forEach(id => {
      const index = state.supportAccessUsers.findIndex(item => item.id === id)
      return index > -1 ? state.supportAccessUsers.splice(index, 1) : ''
    })

    return result
  }

  return {
    state,
    getSupportAccessUsers,
    getSupportAccessDepartments,
    deleteSupportAccess,
    getSupportUsers,
    getSupportUsersType,
    upsertSupportUsers,
    deleteSupportUsers
  }
}
