// WORKAROUND FOR FEATURE: USE_IFRAME_MONOLITH
import { toModernUrl as _toModernUrl } from '@/shared/routeUtils.js'
import { FLAG } from '@/constants/featureFlags.js'
import store from '@/store'
import { computed } from 'vue'
import { useRoute } from '@/router'

const route = useRoute()

export const useIframeMonolith = computed(() =>
  store.getters['featureFlags/isFeatureEnabled'](FLAG.USE_IFRAME_MONOLITH)
)
export const toModernUrl = url =>
  useIframeMonolith.value ? _toModernUrl(url) : url
export const isMonolith = computed(() => route.value?.name === 'iFrameMonolith')
