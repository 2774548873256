import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/constants/contentful'
export const cacheName = '/contentful'
export const renderOptions = {
  renderMark: {
    [MARKS.BOLD]: text => `<b>${text}</b>`
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => {
      return node?.content[0]?.value === '<br>'
        ? '<br>'
        : `<p>${next(node.content)}</p>`
    },
    [BLOCKS.UL_LIST]: (node, next) => `<ul>${next(node.content)}</ul>`,
    [BLOCKS.LIST_ITEM]: (node, next) => `<li>${next(node.content)}</li>`
  }
}

export const defaultLanguage = DEFAULT_LANGUAGE

export const languages = SUPPORTED_LANGUAGES

export const languagesWithoutDefault = languages.filter(t => {
  return t !== defaultLanguage
})
