import { reactive, computed } from 'vue'
import avonovaAPI from '@/config/axios'
import {
  isSuccessResponse,
  requestStarted,
  requestFailed
} from '@/utilities/httpUtilities'

const state = reactive({
  loadedPremise: undefined,
  premisesList: [],
  openedPanels: [],
  loadedPremiseInEditMode: {},
  loadedPremiseInEditModeProxy: {},
  newPremise: {},
  newPremiseProxy: {},
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

const existingPremiseChanged = computed(() => {
  if (
    JSON.stringify(state.loadedPremiseInEditMode) ===
    JSON.stringify(state.loadedPremiseInEditModeProxy)
  )
    return false
  return true
})

const newPremiseIsChanged = computed(() => {
  if (
    JSON.stringify(state.newPremise) === JSON.stringify(state.newPremiseProxy)
  )
    return false
  return true
})

export const usePremisesService = () => {
  const loadAllPremises = async () => {
    state.request = requestStarted()
    avonovaAPI
      .get('/company/api/company/premises/v2')
      .then(response => {
        if (isSuccessResponse(response)) {
          state.premisesList = response.data
        }
      })
      .catch(e => e)
  }

  const deletePremise = async id => {
    return avonovaAPI
      .delete('/company/api/company/premises/' + id)
      .then(response => {
        if (isSuccessResponse(response)) {
          const index = state.premisesList.findIndex(p => p.id == id)
          if (index === -1) return response
          state.premisesList.splice(index, 1)
          return response
        } else {
          state.request = requestFailed()
        }
      })
      .catch(e => e)
  }

  const updatePremises = async premiseToUpdate => {
    return avonovaAPI
      .put('/company/api/company/premises', premiseToUpdate)
      .then(response => {
        if (isSuccessResponse(response)) {
          const index = state.premisesList.findIndex(
            p => p.id == response.data.id
          )
          if (index > -1) state.premisesList.splice(index, 1)
          state.premisesList.push({ ...response.data })
          return response
        } else {
          state.request = requestFailed()
        }
      })
      .catch(e => e)
  }

  return {
    state,
    loadAllPremises,
    deletePremise,
    updatePremises,
    existingPremiseChanged,
    newPremiseIsChanged
  }
}
