import { toRefs, reactive } from 'vue'
import { httpMethods, requestHandlerWithState } from '@/services/requestHandler'
import { orderBy } from '@/utilities/arrayUtilities'

// Docs: https://stamina.atlassian.net/jira/software/projects/SD/boards/3?selectedIssue=SD-823&text=respons
const state = reactive({
  response: {},
  languages: [],
  selectableLanguages: [],
  participants: {
    possibleToday: 0,
    possibleInRound: 0,
    participatedInRound: 0
  },
  report: {},
  firstAvailableDate: null,
  loaded: false,
  writeAccessToResponse: false,
  readAccessToResponse: false,
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

const noToNb = language => (language === 'no' ? 'nb' : language)

const mapLanguagesUsedInResponse = (state, result) => {
  state.responseDtoV2 = result.data.responseDtoV2 || {}

  state.response = result.data.responseDtoV2
  state.participants.possibleToday = result.data.possibleParticipantToday
  if (state.responseDtoV2.questions) {
    state.responseDtoV2.questions = state.responseDtoV2.questions.map(
      question => {
        question.questionTranslations = question.questionTranslations.map(
          questionTranslation => {
            return {
              ...questionTranslation,
              language: noToNb(questionTranslation.language)
            }
          }
        )
        return question
      }
    )
    state.responseDtoV2.categories = state.responseDtoV2.categories.map(
      category => {
        category.categoryTranslations = category.categoryTranslations.map(
          categoryTranslation => {
            return {
              ...categoryTranslation,
              language: noToNb(categoryTranslation.language)
            }
          }
        )
        return category
      }
    )
  }
  state.languages = result.data.languages

  //sort languages by its required property
  state.languages = orderBy(state.languages, item => (item.required ? 0 : 1))
}

export const useResponseService = () => {
  const loadResponse = async () => {
    state.loaded = false
    const url = '/hr/api/response/V2'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.hasContent) {
      mapLanguagesUsedInResponse(state, result)
    }

    state.loaded = true
    return result
  }
  const readAccessToResponse = async () => {
    const url = '/common/api/userrights/schemareadaccess/EMPLOYEE_SURVEY'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.writeAccessToResponse = result.data
    return result
  }

  const writeAccessToResponse = async () => {
    const url = '/common/api/userrights/schemawriteaccess/EMPLOYEE_SURVEY'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.writeAccessToResponse = result.data
    return result
  }

  const createResponse = async () => {
    state.loaded = false
    const url = '/hr/api/response/create/V2'
    const result = await requestHandlerWithState(state, httpMethods.POST, url)
    if (result.success && result.data) {
      mapLanguagesUsedInResponse(state, result)
    }
    state.loaded = true
    return result
  }

  const updateResponse = async () => {
    const url = '/hr/api/response/update/V2'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      state.response
    )
    if (result.success) {
      state.response = result.data
    }
    return result
  }

  const deleteResponse = async () => {
    state.loaded = false
    const url = '/hr/api/response/'
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)
    if (result.success) {
      state.response = {}
      state.report = {}
    }
    state.loaded = true
    return result
  }

  const calculateNextRun = async options => {
    const url = '/hr/api/response/calculateNextRun/V2'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      options
    )
    return result
  }

  const loadTemplateResponse = async () => {
    state.loaded = false
    const url = '/hr/api/response/templateresponse'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success && result.data) {
      const template = result.data.responseTemplateDto
      state.response = {
        ...template,
        categories: template.categories.map(category => {
          return {
            ...category,
            categoryTranslations: category.categoryTranslations.map(
              translation => {
                return {
                  ...translation,
                  language: noToNb(translation.language)
                }
              }
            )
          }
        }),
        questions: template.questions.map(question => {
          return {
            ...question,
            questionTranslations: question.questionTranslations.map(
              translation => {
                return {
                  ...translation,
                  language: noToNb(translation.language)
                }
              }
            )
          }
        })
      }
    }
    state.loaded = true
    return result
  }

  const updateTemplateResponse = async () => {
    state.loaded = false
    const url = '/hr/api/response/updateTemplateResponse/V2'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      state.response
    )
    state.loaded = true
    return result
  }

  const loadResponseReport = async (options = {}) => {
    state.loaded = false
    const url = '/hr/api/responsereport/reportV2'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      options
    )
    if (result.success && result.data) {
      state.report = result.data
      state.participants.participatedInRound =
        result.data.activeRoundParticipantsSoFar
      state.participants.possibleInRound =
        result.data.activeRoundPossibleParticipants
    } else {
      state.report = {}
    }
    state.loaded = true
    return result
  }

  const loadFirstAvailableDate = async () => {
    const url = '/hr/api/response/minvaluefornextrun'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.firstAvailableDate = result.data.minNextRunDate
    }
    return result
  }

  const updateNextRun = async options => {
    const optionParams = {
      active: state.response.active || false,
      nextRunDate: state.response.nextRunDate || state.response.firstRunDate,
      answerPeriod: state.response.answerPeriod || null,
      repeatDay: state.response.repeatDay || null,
      repeatMonth: state.response.repeatMonth || null,
      repeatEveryNth: state.response.repeatEveryNth || null,
      repeatInterval: state.response.repeatInterval || null,
      ...options
    }
    const url = '/hr/api/response/updateNextRun/V2'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      optionParams
    )
    if (result.success && result.data) {
      state.response = result.data.responseDtoV2
      state.participants.possibleToday = result.data.possibleParticipantToday
      state.languages = result.data.languages
    }
    return result
  }

  const getLanguages = async () => {
    const url = '/hr/api/response/availableLanguages'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) {
      state.selectableLanguages = result.data
    }
  }

  return {
    state: toRefs(state),
    loadResponse,
    createResponse,
    updateResponse,
    deleteResponse,
    calculateNextRun,
    loadTemplateResponse,
    updateTemplateResponse,
    loadResponseReport,
    loadFirstAvailableDate,
    readAccessToResponse,
    writeAccessToResponse,
    updateNextRun,
    getLanguages
  }
}
