import { reactive, toRefs } from 'vue'
import { requestHandler, httpMethods } from '../requestHandler'
import { useLogger } from '@/shared/logger'

const accessTypes = {
  'NO_ACCESS': {
    read: false,
    write: false
  },
  'READ_ACCESS': {
    read: true,
    write: false
  },
  'READ_WRITE_ACCESS': {
    read: true,
    write: true
  }
}

const accessAreas = {
  myCompetence: 'MY_COMPETENCE',
  myAbsence: 'FRAVAER',
  absence: 'EDITFRAVAER',
  absenceFollowUp: 'OPPFOLGINGSYKEMELDTE',
  myHours: 'MINETIMER',
  incidentsUserAccess: 'EDITHENDELSE',
  myIncidentsUserAccess: 'MINE_HENDELSER',
  actionPlan: 'EDITTILTAK',
  competence: 'EDITKOMPETANSEOVERSIKT',
  inspectionsUserAccess: 'EDITTILSYNSRAPPORT',
  vacation: 'EDITFERIE',
  myVacation: 'FERIE',
  files: 'DOKUMENTER',
  organisationalCharts: 'ORGKART'
}
const state = reactive({
  cachedAccessRights: {}
})

export const useAccountService = () => {
  const logger = useLogger('account-service')

  const getAccessRights = async accessArea => {
    // Check if the access rights for the area is already fetched
    if (state.cachedAccessRights[accessArea])
      return state.cachedAccessRights[accessArea]

    const url = `/common/api/userrights/schemaaccessright/${accessArea}`

    const response = await requestHandler(httpMethods.GET, url)

    logger.debug(
      `getAccessRights area: ${accessArea}, accessright: ${response.data}`
    )

    const accessRight = {
      area: accessArea,
      ...accessTypes[response.data]
    }

    state.cachedAccessRights[accessArea] = accessRight

    return accessRight
  }

  const getAccessRightsWithId = async (locationId, accessArea) => {
    const url = `/common/api/userrights/schemaaccessright/${
      (locationId, accessArea)
    }`

    const locationInfo = {
      locationId,
      accessAreas
    }

    const response = await requestHandler(httpMethods.GET, url, locationInfo)

    return {
      area: accessArea,
      id: locationId,
      ...accessTypes[response.data]
    }
  }

  const getFeatureFlags = async () => {
    const response = await requestHandler(
      httpMethods.GET,
      '/common/api/accounts/feature-flags'
    )
    if (response.success) return response.data
    return []
  }

  return {
    accessAreas,
    getAccessRights,
    getAccessRightsWithId,
    getFeatureFlags
  }
}
