import { reactive } from 'vue'
import { DateTime } from 'luxon'

export const followUpDto = () => {
  let dto = reactive({
    'employeeId': 0,
    'created': DateTime.now().toISODate(),
    'firstDayOfAbsence': '',
    'supervisor': '',
    'commune': '',
    'supervisorPhone': '',
    'backToWorkDate': '',
    'percent': '',
    'currentTasks': '',
    'tasksWithoutFacilitation': '',
    'tasksWithFacilitation': '',
    'workOpportunities': '',
    'employerMeasures': '',
    'employerPerformedMeasures': '',
    'dialogueMeetingDate': '',
    'dialogueMeetingDescription': '',
    'authoritiesGuidanceAndCounceling': false,
    'authoritiesEconomical': false,
    'authoritiesAids': false,
    'authoritiesNoNeedForMeasuresHelp': false,
    'companyHealthService': false,
    'physician': false,
    'assistanceFromOther': '',
    'assistanceFromAuthoritiesOther': '',
    'completed': false,
    'events': [],
    'writeaccess': true
  })
  return dto
}
