const riskManagementRoutes = [
  {
    path: '/risk-management/risk-criteria',
    params: true,
    name: 'RiskCriteriaList',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "RiskCriteriaListHero" */ `@/views/risk-management/components/RiskCriteriaListHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "RiskCriteriaList" */ `@/views/risk-management/RiskCriteriaList.vue`
        )
    },
    meta: {
      title: 'meta__title__risk_criteria',
      contentful: {
        category: '7BoIeAnCCiLZWCsy2hmFNw',
        pageHelp: '01C0CtP7jHleCyqlrFEJDf'
      },
      breadcrumbs: [
        {
          text: 'Risikosenter',
          string: 'menu__item__title__risk_management',
          href: '/startside/dashboards/dashboard_riskcenter.xhtml'
        },
        {
          text: 'Risikokriterier',
          string: 'meta__title__risk_criteria'
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: '/risk-management/risk-criteria/:id?',
    name: 'RiskCriteria',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "RiskCriteriaHero" */ `@/views/risk-management/components/RiskCriteriaHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "RiskCriteria" */ `@/views/risk-management/RiskCriteria.vue`
        )
    },
    meta: {
      title: 'meta__title__risk_criteria',
      contentful: {
        category: '7BoIeAnCCiLZWCsy2hmFNw',
        pageHelp: '01C0CtP7jHleCyqlrFEJDf'
      },
      breadcrumbs: [
        {
          text: 'Risikosenter',
          string: 'menu__item__title__risk_management',
          href: '/startside/dashboards/dashboard_riskcenter.xhtml'
        },
        {
          text: 'Risikokriterier',
          string: 'risk_criteria__title',
          href: '/risk-management/risk-criteria'
        },
        {
          text: 'Risikokriterium',
          string: 'risk_criteria__singular_criterion'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/risk-management/create-risk-criteria',
    name: 'CreateRiskCriteria',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "CreateRiskCriteriaHero" */ `@/views/risk-management/components/CreateRiskCriteriaHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "CreateRiskCriteria" */ `@/views/risk-management/CreateRiskCriteria.vue`
        )
    },
    meta: {
      title: 'risk_criteria__title_new',
      contentful: {
        category: '7BoIeAnCCiLZWCsy2hmFNw',
        pageHelp: '01C0CtP7jHleCyqlrFEJDf'
      },
      breadcrumbs: [
        {
          text: 'Risikosenter',
          string: 'menu__item__title__risk_management',
          href: '/startside/dashboards/dashboard_riskcenter.xhtml'
        },
        {
          text: 'Risikokriterier',
          string: 'risk_criteria__title',
          href: '/risk-management/risk-criteria'
        },
        {
          text: 'Nytt risikokriterium',
          string: 'risk_criteria__title_new'
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: '/risk-management/risk-assessments',
    params: true,
    name: 'RiskAssessments',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "RiskAssessmentListHero" */ `@/views/risk-management/riskAssessments/components/RiskAssessmentListHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "RiskAssessmentList" */ `@/views/risk-management/riskAssessments/RiskAssessmentList.vue`
        )
    },
    meta: {
      title: 'meta__title__risk_criteria',
      contentful: {
        category: '7BoIeAnCCiLZWCsy2hmFNw',
        pageHelp: '01C0CtP7jHleCyqlrFEJDf'
      },
      breadcrumbs: [
        {
          text: 'Risikosenter',
          string: 'menu__item__title__risk_management',
          href: '/startside/dashboards/dashboard_riskcenter.xhtml'
        },
        {
          text: 'Risikovurderinger',
          string: 'menu__item__risk_assessments'
        }
      ]
    },

    productionAvailable: false
  },
  {
    path: '/risk-management/risk-assessments/:id',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "RiskAssessmentHero" */ `@/views/risk-management/riskAssessments/components/RiskAssessmentHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "RiskAssessment" */ `@/views/risk-management/riskAssessments/RiskAssessment.vue`
        )
    },
    meta: {
      title: 'meta__title__risk_assessment',
      contentful: {
        category: '7BoIeAnCCiLZWCsy2hmFNw',
        pageHelp: '01C0CtP7jHleCyqlrFEJDf'
      },
      breadcrumbs: [
        {
          text: 'Risikosenter',
          string: 'menu__item__title__risk_management',
          href: '/startside/dashboards/dashboard_riskcenter.xhtml'
        },
        {
          text: 'Risikovurderinger',
          string: 'menu__item__risk_assessments',
          href: '/risk-management/risk-assessments'
        },
        {
          text: 'Risikovurdering',
          string: 'risk_assessments__edit_risk_assessment'
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'RiskAssessment',
        component: () =>
          import(
            /* webpackChunkName: "RiskAssessment" */ `@/views/risk-management/riskAssessments/RiskAssessment.vue`
          ),
        meta: {
          title: 'risk_assessments__edit_risk_assessment',
          contentful: {
            category: 'PTRYWviqqOePp2mXVa86B',
            pageHelp: 'R7oV0MFgjt6o7oew65ua9'
          }
        }
      }
    ],
    productionAvailable: false
  }
]

export default riskManagementRoutes
