import i18n from '@/config/i18n'
import { mapi18nToISO } from '@/config/i18n/localization'
import {
  localizeDate as _localizeDate,
  localeFormatToDateString as _localeFormatToDateString,
  twoDatesMinuteDifference as _twoDatesMinuteDifference
} from '@hms-kontoret/avonova-storybook/src/composable/common/dates'

const timestampToDate = function (timestamp) {
  if (timestamp > 0) {
    const date = new Date(timestamp)
    return date.toLocaleDateString()
  }
  return timestamp
}

const timestampToTime = function (timestamp) {
  if (timestamp > 0) {
    const date = new Date(timestamp)
    return date.toLocaleTimeString()
  }
  return timestamp
}

const getLocaleCode = function () {
  // Map i18n.locale to ISO language codes
  return mapi18nToISO(i18n.locale)
}

const localizeDate = function (date, format = 'short') {
  return _localizeDate(date, format, getLocaleCode())
}

const localeFormatToDateString = function (string) {
  return _localeFormatToDateString(string, getLocaleCode())
}

/**
 * Returns how many whole minutes (if any) the two dates (in millis) are
 * apart from each other (in any direction).
 *
 * @param {number} d1 Date 1 in millis
 * @param {number} d2 Date 2 in millis
 * @return {number} Positive number of whole minutes the two dates are apart
 */
const twoDatesMinuteDifference = (d1, d2) => {
  return _twoDatesMinuteDifference(d1, d2)
}

const getDayOfWeek = date => {
  let d = date
  if (typeof d === 'string') d = new Date(d)
  else if (typeof d === 'number') d = new Date(d)
  if (typeof d?.getDay !== 'function') return ''
  const weekday =
    [
      i18n.t('common__sunday'),
      i18n.t('common__monday'),
      i18n.t('common__tuesday'),
      i18n.t('common__wednesday'),
      i18n.t('common__thursday'),
      i18n.t('common__friday'),
      i18n.t('common__saturday')
    ][d.getDay()] || i18n.t('common__unknown')
  return weekday
}

export {
  timestampToDate,
  timestampToTime,
  getLocaleCode,
  localizeDate,
  localeFormatToDateString,
  twoDatesMinuteDifference,
  getDayOfWeek
}
