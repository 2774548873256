/* eslint-disable no-console */
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import env from '@/environment'

const configureSentry = router => {
  Sentry.init({
    Vue,
    dsn: env.VUE_APP_SENTRY_DSN,
    release: 'frontend_vue@' + env.VUE_APP_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [env.VUE_APP_SENTRY_PROPAGATION_TARGETS]
      })
    ],
    environment: env.VUE_APP_ENVIRONMENT,
    tracesSampleRate: env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
  })

  Sentry.setTag('commitId', env.VUE_APP_GIT_HASH)
}

export default configureSentry
