import { reactive, toRefs } from 'vue'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

import { requestStarted, requestFinished } from '@/utilities/httpUtilities'
import { hasItems } from '../utilities/conditionalUtilities'
import { toObject, removeItemAtIndex } from '../utilities/arrayUtilities'
import { addNewItem } from '@/utilities/table'
import { useFilesService } from './files-service'

const state = reactive({
  competencies: [],
  competence: {},
  competenceTypes: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useCompetenceService = () => {
  const getCompetencies = async () => {
    const url = `/hr/api/competence/myCompetencies`

    state.request = requestStarted()
    const result = await requestHandler(httpMethods.GET, url)

    const confirmedIds = result.data.filter(d => d.confirmed).map(d => d.id)
    const pendingIds = result.data.filter(d => !d.confirmed).map(d => d.id)

    const confirmedFileCount = await getCompetenceFileCount(
      'INDEPENDENT_COMPETENCE',
      confirmedIds
    )

    const pendingFileCount = await getCompetenceFileCount(
      'MY_COMPETENCE',
      pendingIds
    )

    state.competencies = result.data.map(competence => {
      competence['fileCount'] = competence.confirmed
        ? confirmedFileCount[competence.id].count
        : pendingFileCount[competence.id].count

      return competence
    })
    state.request = requestFinished()
  }

  const createCompetence = async (data, fileCount) => {
    // id is empty
    const url = `/hr/api/competence/updateMyCompetence`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    if (result.success)
      addNewItem({ ...result.data, fileCount }, state.competencies)
    return result
  }

  const updateCompetence = async data => {
    // needs a id
    const url = `/hr/api/competence/updateMyCompetence`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    return result
  }

  const getCompetenceTypes = async () => {
    const url = `/hr/api/competence/filter/competenceTitles`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.competenceTypes = result.data
  }

  const removeCompetenceById = async id => {
    const url = `/hr/api/competence/deleteCompetenceToBeConfirmed/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)
    if (result.data.success) removeItemAtIndex(state.competencies, id)
  }

  const getCompetenceFileCount = async (ownerType, ids) => {
    if (!hasItems(ids)) return
    const { getFileCountsByOwnerIds } = useFilesService()
    const fileCounts = await getFileCountsByOwnerIds(ids, ownerType)
    return toObject(fileCounts, item => item.id)
  }

  const getCompetence = async (id, isConfirmed) => {
    state.filesForCurrentCompetence = []
    const url = `/hr/api/competence/myCompetence/${id}/${isConfirmed}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.competence = result.data
  }

  return {
    state: toRefs(state),
    getCompetencies,
    removeCompetenceById,
    createCompetence,
    updateCompetence,
    getCompetenceTypes,
    getCompetence
  }
}
