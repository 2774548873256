import routes from '@/router/routes'
import { isLegacyUrl } from './routeUtils'
import env from '@/environment'

const routeMapping = {
  'Absence': {
    oldPath: '/ansatte/fravaer/mittFravaer.xhtml',
    newPath: '/employee/absence'
  },
  'Competence': {
    oldPath: '/kompetanseoversikt/my_competence.xhtml',
    newPath: '/employee/competence'
  },
  'ActionPlan': {
    oldPath: '/hendelsesregister/tiltak/yearplan.xhtml',
    newPath: '/incidentregistry/actionplan'
  },
  'AdminAccountOverview': {
    oldPath: '/admintools/kontoadmin.xhtml',
    newPath: '/admintools/overview'
  },
  'AdminAccountSupportAccess': {
    oldPath: '/admintools/supportaccess.xhtml',
    newPath: '/admintools/supportaccess'
  },

  'AdminAccountSupportUsers': {
    oldPath: '/admintools/supportusers.xhtml',
    newPath: '/admintools/supportusers'
  },
  'Inspections': {
    oldPath: '/hendelsesregister/tilsynsrapport/tilsynsrapporter.xhtml',
    newPath: '/incidentregistry/inspections'
  },
  'MyHours': {
    oldPath: '/timeregistrering/minesider_timefoering.xhtml',
    newPath: '/employee/my-hours/'
  },
  'MyVacation': {
    oldPath: '/ansatte/ferie/minferie.xhtml',
    newPath: '/employee/vacation'
  },
  'MyIncidents': {
    oldPath: '/hendelsesregister/hendelse/mineHendelser.xhtml',
    newPath: '/employee/my-incidents'
  },
  'SafetyInspections': {
    oldPath: '/hendelsesregister/vernerunde/vernerunder.xhtml',
    newPath: '/incidentregistry/safety-inspections'
  },
  'RiskAssessments': {
    oldPath: '/risikosenter/ros/riskAssessmentsOverview.xhtml',
    newPath: '/risk-management/risk-assessments'
  },
  'CustomerSupport': {
    oldPath: '/kontrollpanel/supportAccounts.xhtml',
    newPath: '/control-panel/customer-support'
  },
  'EmployerCompetence': {
    oldPath: '/kompetanseoversikt/kompetanseoversikt.xhtml',
    newPath: '/employer/competence'
  },
  'EmployerAbsence': {
    oldPath: '/ansatte/fravaer/fravaer.xhtml',
    newPath: '/employer/absence'
  },
  'EmployerVacation': {
    oldPath: '/ansatte/ferie/ferie.xhtml',
    newPath: '/employer/vacation'
  }
}

export const getRouteHrefByName = (routeName) => {
  // Filter out iFrames and get routes available in production
  const allRoutes = Object.values(routes)
    .flatMap(obj => Object.values(obj))
    .filter(x => x.name !== 'iFrameMonolith');

  // Check if the route exists in the mapping and handle accordingly
  const routeInfo = routeMapping[routeName];
  if (!routeInfo) {
    throw new Error(`Route "${routeName}" not found in route mapping.`);
  }

  if (env.VUE_APP_ENVIRONMENT === 'local') return routeInfo.newPath;
  return allRoutes.some(x =>
    x.name === routeName && x.productionAvailable ||
    x.name === routeName && x.productionAvailable == null
  ) ? routeInfo.newPath : routeInfo.oldPath;
}


export const isLegacy = url => isLegacyUrl(url)