import axios from 'axios'
import i18n from './i18n'
import env from '@/environment'
import { authService } from '@/services/'
import { useAmberAPI } from '@/utilities/iframe'
const avonovaAPI = axios.create()

const getToken = async () => {
  const amberAPI = useAmberAPI()
  let tokenPrefix = 'Token '

  if (amberAPI) {
    const { idToken } = await amberAPI.v1.authContext.getToken()
    return tokenPrefix + idToken
  } else return tokenPrefix + authService.getToken()
}

avonovaAPI.interceptors.request.use(
  async config => {
    config.baseURL = env.VUE_APP_API_URL

    // Handle async token retrieval
    const tokenPromise = getToken() // This is now a promise
    return tokenPromise.then(token => {
      config.headers.common['Authorization'] = token

      // Add accept language
      const language = i18n.locale
      config.headers['ACCEPT-LANGUAGE'] = language

      return config // Return config after token is set
    })
  },
  error => {
    return Promise.reject(error)
  }
)

avonovaAPI.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export default avonovaAPI
