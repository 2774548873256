import { httpMethods, requestHandler } from '@/services/requestHandler'
import { CONTENT_TYPES } from '@/constants/metaData'
import { eventBus, events } from '@/shared'

export const useUserService = () => {
  const updateUserLocale = async language => {
    await requestHandler(
      httpMethods.PUT,
      '/common/api/users/my/locale',
      language,
      {
        headers: {
          'Content-Type': CONTENT_TYPES.APPLICATION_JSON
        }
      }
    )
    eventBus.$emit(events.localeChanged)
  }

  return { updateUserLocale }
}
