export function useEmployeeHoursBreadcrumbs() {
  const myHoursBreadcrumbs = {
    parent: [
      {
        text: 'My pages',
        string: 'menu__item__title__my_pages',
        disabled: false,
        href: '/index.xhtml'
      },
      {
        text: 'My hours',
        string: 'meta__title__hours',
        href: '/employee/my-hours',
        disabled: false
      }
    ],

    registration: {
      text: 'Registration',
      string: 'meta__title_employee_hour_registration'
    },

    hourAccount: {
      text: 'Hour Account',
      string: 'meta__title_employee_hour_hour_account'
    },

    history: {
      text: 'History',
      string: 'meta__title_employee_hour_history'
    }
  }

  return {
    myHoursBreadcrumbs
  }
}

export const useEmployeeBreadcrumbs = () => {
  const myVacationBreadcrumbs = {
    parent: [
      {
        text: 'Unit information',
        string: 'company__unit_info',
        disabled: false,
        href: '/startside/dashboards/dashboard_company_information.xhtml'
      },
      {
        text: 'My vacation',
        string: 'my-vacation__title',
        href: '/employee/vacation',
        disabled: false
      }
    ],
    myOverview: {
      text: 'My overview',
      string: 'my-vacation__overview_title'
    },
    myColleagues: {
      text: 'My colleagues',
      string: 'my-vacation__colleagues_title'
    }
  }

  return {
    myVacationBreadcrumbs
  }
}
