import { reactive } from 'vue'
import store from '@/store'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

export const useCompanyInformationService = () => {
  const state = reactive({
    companyInformationData: {},
    ohsActivated: false,
    readAccess: false,
    writeAccess: false,
    writeAccessToContacts: false,
    request: {
      loaded: false,
      loading: false,
      saving: false,
      errors: []
    }
  })

  const getCompanyInformation = async () => {
    const url = '/company/api/company'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.readAccess = result.data.readaccess
      state.writeAccess = result.data.writeaccess
      state.companyInformationData = result.data
      store.commit('storeCompanyInfo', result.data)
    } else {
      state.readAccess = false
      state.writeAccess = false
    }
    writeAccessToContacts()
  }

  const saveCompanyInformation = async () => {
    const url = '/company/api/company'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      state.companyInformationData
    )
    if (result.success) {
      state.readAccess = result.data.readaccess
      state.writeAccess = result.data.writeaccess
    }
    return result
  }

  const writeAccessToContacts = async () => {
    const url = '/common/api/userrights/schemawriteaccess/KONTAKTER'
    const result = await requestHandler(httpMethods.GET, url)

    if (result.success) {
      state.writeAccessToContacts = result.data
    } else {
      state.writeAccessToContacts = false
    }
  }

  return {
    state,
    getCompanyInformation,
    saveCompanyInformation
  }
}
