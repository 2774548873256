const personnelRoutes = [
  {
    path: '/personnel/response/setup',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ResponseNewIdentityHero" */ `@/views/personnel/response/response-new/ResponseNewIdentityHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ResponseOverview" */ `@/views/personnel/response/response-new/ResponseNewIdentity.vue`
        )
    },

    meta: {
      title: 'response__title',
      breadcrumbs: [
        {
          text: 'Personal',
          string: 'response__employee_survey_title',
          href: '/personnel/response'
        },
        {
          text: 'Personal',
          string: 'response__new_survey'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/personnel/response',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ResponseOverviewHero" */ `@/views/personnel/response/response-new/ResponseSurveyOverviewHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ResponseOverview" */ `@/views/personnel/response/response-new/ResponseSurveyOverview.vue`
        )
    },
    meta: {
      title: 'response__title',
      breadcrumbs: [
        {
          text: 'Personal',
          string: 'menu__item__title__personnel',
          href: '/startside/dashboards/dashboard_personnel.xhtml'
        },
        {
          text: 'Personal',
          string: 'response__employee_survey_title',
          href: '/personnel/response'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/personnel/response/template',
    name: 'ResponseTemplate',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ResponseTemplateHero" */ `@/views/personnel/response/ResponseTemplateHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ResponseTemplate" */ `@/views/personnel/response/ResponseTemplate.vue`
        )
    },
    meta: {
      title: 'Avonova Digital - Respons',
      breadcrumbs: [
        {
          text: 'Personal',
          string: 'menu__item__title__personnel',
          href: '/startside/dashboards/dashboard_personnel.xhtml'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/personnel/my-response/',
    name: 'MyResponse',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "MyResponseHero" */ `@/views/personnel/my-response/MyResponseHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "MyResponse" */ `@/views/personnel/my-response/MyResponse.vue`
        )
    },
    meta: {
      title: 'menu__item__my_response',
      breadcrumbs: [
        {
          text: 'My pages',
          string: 'menu__item__title__my_pages',
          href: '/index.xhtml'
        },
        {
          text: 'My response',
          string: 'myresponse__title'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: '/personnel/my-response-answer/',
    name: 'MyResponseAnswer',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "MyResponseHero" */ `@/views/personnel/my-response/AnswerSurvey/MyResponseSurveyHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "MyResponse" */ `@/views/personnel/my-response/AnswerSurvey/MyResponseSurvey.vue`
        )
    },
    meta: {
      title: 'menu__item__my_response'
    },
    productionAvailable: true
  }
]

export default personnelRoutes
