import { reactive, toRefs } from 'vue'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

import { requestStarted, requestFinished } from '@/utilities/httpUtilities'

const state = reactive({
  schedule: []
})

export const useScheduleService = () => {
  const getWatchesForEmployee = async (employeeid, from, to) => {
    const url = `/hr/api/workschedule/getWatchesForEmployee/${employeeid}/${from}/${to}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.schedule = result.data
    }
  }
  const getWorkScheduleForEmployee = async (employeeid, from, to) => {
    const url = `/hr/api/workschedule/getWorkScheduleForEmployee/${employeeid}/${from}/${to}`
    const result = await requestHandler(httpMethods.GET, url)
    return result
  }

  return {
    state: toRefs(state),
    getWatchesForEmployee,
    getWorkScheduleForEmployee
  }
}
