export const PARENT_BREADCRUMBS = [
  {
    text: 'Hendelsesregister',
    string: 'menu__item__title__incident_registry',
    disabled: false,
    href: '/startside/dashboards/dashboard_eventregistry.xhtml'
  },
  {
    text: 'SafetyInspections',
    string: 'menu__item__safety_inspections',
    disabled: false,
    href: '/incidentregistry/safety-inspections'
  }
]
