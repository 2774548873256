<template>
  <div>
    <AvonovaSection :hasHeading="false">
      <template v-slot:content>
        <div class="d-flex flex-column justify-center text-center">
          <h2>{{ title }}</h2>
          <AvonovaIconAndGraphic
            class="align-self-center mb-4"
            :icon="icon"
            graphicBlob="Blob3"
            :graphicSize="212"
            :iconSize="120"
          />
          <span class="paragraph-d" v-html="$sanitize(description)" />
          <AvonovaButton
            v-if="hasButton"
            :text="buttonText"
            :aria-label="buttonText"
            id="access-page-button"
            @click="$emit('buttonClick')"
            class="align-self-sm-center mobile mt-4"
          />
        </div>
      </template>
    </AvonovaSection>
  </div>
</template>
<script>
import {
  AvonovaIconAndGraphic,
  AvonovaSection,
  AvonovaButton
} from '@hms-kontoret/avonova-storybook'
export default {
  name: 'GeneralAccessPage',
  components: { AvonovaIconAndGraphic, AvonovaSection, AvonovaButton },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    icon: { type: String, required: true },
    hasButton: { type: Boolean, default: false },
    buttonText: { type: String }
  }
}
</script>
<style>
p {
  margin-bottom: 0px !important;
  font-size: inherit !important;
}
</style>
