/* eslint-disable no-console */
import { isSomething } from '../utilities/conditionalUtilities'
import env from '@/environment'

const logType = {
  debug: 'log',
  error: 'error',
  info: 'info',
  warning: 'warn'
}

export const useLogger = (scope = '') => {
  const format = message =>
    isSomething(scope) ? `${scope} ${message}` : message

  const isProduction = env.VUE_APP_ENVIRONMENT === 'prod'

  const print = (type, message, optional) => {
    if (isProduction) return

    if (isSomething(optional)) {
      console[type](format(message), optional)
    } else {
      console[type](format(message))
    }
  }

  // eslint-disable-next-line
  const debug = (message, optional) => print(logType.debug, message, optional)

  // eslint-disable-next-line
  const error = (message, optional) => print(logType.error, message, optional)

  // eslint-disable-next-line
  const info = (message, optional) => print(logType.info, message, optional)

  // eslint-disable-next-line
  const warning = (message, optional) =>
    print(logType.warning, message, optional)

  return {
    debug,
    error,
    info,
    warning
  }
}
