import { reactive, toRefs } from 'vue'
import { getWeekNumber } from '@/utilities/dateUtilities'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'
import { addNewItem } from '@/utilities/table'
import i18n from '@/config/i18n'
import { isSomething } from '../utilities/conditionalUtilities'

const state = reactive({
  selectedYear: new Date().getFullYear(),
  selectedWeek: getWeekNumber(new Date()),
  selectableYears: [],
  selectableMonths: [],
  selectedMonth: new Date().getMonth(),
  employeePositions: [],
  vacationPeriods: [],
  vacations: [],
  statistics: {
    totalDays: 0,
    usedDays: 0,
    transferred_previousYearDays: 0,
    year: 0
  },
  vacationYears: [],
  colleaguesVacation: [],
  weeklyColleaguesVacation: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useVacationService = () => {
  const getVacationOverview = async (employeeId, year) => {
    const url = `/hr/api/vacation/employeeVacationOverview/${employeeId}/${year}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.vacations = result.data
  }

  const getVacationStatistics = async (employeeId, year) => {
    const url = `hr/api/vacation/employeeDaysCounters/${employeeId}/${year}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.statistics = result.data
  }

  const getVacationStatisticsForVacationPeriod = async (
    employeeId,
    periodStartYear
  ) => {
    const url = `hr/api/vacation/employeeDaysCountersByYearPeriod/${employeeId}/${periodStartYear}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.statistics = result.data
  }

  const addVactionDaysToEmployees = async (
    employeeIds,
    year,
    days,
    discardPrevious
  ) => {
    const url = `hr/api/vacation/addVactionDaysToEmployees`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      employeeIds,
      year,
      days,
      discardPrevious
    })
    if (result.success) return result
  }

  const transferVacationDays = async (
    toYear,
    maxTransferDays,
    overwrite = true
  ) => {
    const url = `hr/api/vacation/transferVactionDays/${toYear}/${maxTransferDays}/${overwrite}`
    const result = await requestHandlerWithState(state, httpMethods.POST, url)

    return result
  }

  const removeVacationById = async (id, message, employeeId) => {
    const url = `hr/api/vacation/removeVacationRequest/${id}/${message}`
    const result = await requestHandler(httpMethods.POST, url, {
      id,
      message
    })
    if (result.success) {
      const index = state.vacations.findIndex(item => item.id === id)
      if (index > -1) {
        state.vacations.splice(index, 1)
      }

      if (isSomething(employeeId)) await getVactionYearsByEmployeeId(employeeId)
    }
  }

  const selectYear = year => (state.selectedYear = year)

  const saveVacationRequest = async data => {
    const url = '/hr/api/vacation/savevacactionrequest'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    if (result.success) {
      data.id = result.data.object_id
      addNewItem(result.data, state.vacations)

      await getVactionYearsByEmployeeId(data.employee_id)
    }
    return result
  }

  const getSelectableMonths = async () => {
    const url = '/hr/api/planningoverview/getSelectableMonths'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.selectableMonths = result.data
  }

  const getSelectableYears = async () => {
    const url = '/hr/api/planningoverview/getSelectableYears'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.selectableYears = result.data
  }

  const getEmployeePositions = async () => {
    state.employeePositions = []
    const url = '/hr/api/planningoverview/getEmployeePositions'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.employeePositions.push(i18n.t('common__all_positions'))
    result.data.forEach(position => {
      state.employeePositions.push(position)
    })
  }

  const getWeeklyColleaguesOverview = async (data, firstOrLastWeek) => {
    const url = '/hr/api/planningoverview/getOverviewData'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    if (firstOrLastWeek === 'FIRST') {
      state.selectedWeek = 0
    } else if (firstOrLastWeek === 'LAST') {
      state.selectedWeek = result.data.weeks[result.data.weeks.length - 1]
    } else if (firstOrLastWeek === 'CURRENT') {
      state.selectedWeek = getWeekNumber(new Date())
    }
    state.weeklyColleaguesVacation = result.data
  }

  const getOverviewDataForCalendar = async (from, to) => {
    const url = '/hr/api/planningoverview/getOverviewData/v2'
    const elementTypes = ['HOLIDAYS', 'VACATION', 'VACATION_REQUEST']
    const accessContext = 'MYVACATION'
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      from,
      to,
      elementTypes,
      accessContext,
      showAllEmployees: true
    })
    state.colleaguesVacation = result.data
  }

  const getVactionYearsByEmployeeId = async employeeId => {
    const url = `hr/api/vacation/findVacationYearsByEmployeeId/${employeeId}`
    const result = await requestHandler(httpMethods.GET, url)

    state.vacationYears = result.data
  }

  const findSelectableMonths = async periodStartYear => {
    //Meant to be used in conjunction with vactionPeriod
    const url = `hr/api/vacation/findSelectableMonths/${periodStartYear}`
    const result = await requestHandler(httpMethods.GET, url)
    return result.data
  }

  const findVacationPeriods = async () => {
    const url = `/hr/api/vacation/findVacationPeriods`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.vacationPeriods = result.data
  }

  return {
    state: toRefs(state),
    getVacationOverview,
    saveVacationRequest,
    getVacationStatistics,
    removeVacationById,
    selectYear,
    getSelectableMonths,
    getSelectableYears,
    getOverviewDataForCalendar,
    getWeeklyColleaguesOverview,
    getEmployeePositions,
    getVactionYearsByEmployeeId,
    addVactionDaysToEmployees,
    transferVacationDays,
    findSelectableMonths,
    findVacationPeriods,
    getVacationStatisticsForVacationPeriod
  }
}
